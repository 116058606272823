// Calls View
//
// @author Erik Galloway <egalloway@claruscare.com>
//
// Action bar
// View
// Column
// Call info block

// Action bar

.call-action-bar {
	background: $white;
	border: 1px solid $gray-400;
	border-radius: 0.25rem;
	display: flex;
	flex-shrink: 0;
	height: 3.5rem;
	justify-content: space-between;
	margin: 1rem;
	overflow-x: auto;
}

.call-actions {
	align-items: center;
	display: flex;
}

.call-action {
	align-items: center;
	background: $white;
	border-left: 1px solid transparent;
	border-right: 1px solid transparent;
	color: $purple;
	display: inline-flex;
	font-size: 1.1rem;
	height: 100%;
	justify-content: center;
	padding: 0 1rem;
	text-transform: uppercase;
	transition: all 150ms $animation-standard-timing;
	white-space: nowrap;

	@include letter-spacing(1.5, 0.875rem);
	@include font-roboto($font-size: 0.875rem, $font-weight: 500);

	.call-actions:first-child &:first-child,
	.call-actions:first-child &:first-child:hover,
	.call-actions:first-child &:first-child:focus {
		border-left: 0;
	}

	.call-actions:not(:first-child) > &:last-child,
	.call-actions:not(:first-child) > &:last-child:hover,
	.call-actions:not(:first-child) > &:last-child:focus {
		border-right: 0;
	}

	&:focus {
		background: rgba($purple, 0.08);
		border-color: $gray-300;
		color: $purple;
		outline: 0;
	}

	&:hover {
		background: rgba($purple, 0.04);
		border-color: $gray-300;
		color: $purple;
		text-decoration: none;
	}
	span {
		margin-right: 12px;
	}
}

.call-action-icon {
	align-items: center;
	display: inline-flex;
	justify-content: center;
	margin-right: 0.5rem;
	.backicon {
		path {
			stroke: #6200ee;
		}
	}
}

// View
.calls-show > .call {
	display: flex;
	flex-direction: column;
	margin-top: $navbar-height;
	overflow-x: hidden;
	overflow-y: auto;
	width: 100%;

	.call-container {
		display: flex;
		flex-direction: column;
		min-height: fit-content;
		width: 100%;
	}

	.call-columns {
		display: flex;
		flex-direction: column;

		.call-message {
			flex-direction: column-reverse;
		}

		@include media-breakpoint-up(lg) {
			flex-flow: row nowrap;
		}
	}
}

// Column

.call-column {
	display: flex;
	flex-direction: column;
	flex-shrink: 0;
	width: 100%;

	&.call-info {
		padding-left: 1rem;
		padding-right: 1rem;

		@include media-breakpoint-only(md) {
			order: 1;
		}

		@include media-breakpoint-up(lg) {
			height: 100%;
			padding-right: 0.5rem;
			width: percentage(5 * 0.125);
		}
	}

	&.patient-info {
		padding-left: 1rem;
		padding-right: 1rem;

		@include media-breakpoint-only(md) {
			order: 2;
		}

		@include media-breakpoint-up(lg) {
			height: 100%;
			padding-left: 0.5rem;
			width: percentage(3 * 0.125);
		}
	}
}

// Call info block

.call-info-block {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding-top: 1rem;
	width: 100%;

	&:last-child {
		margin-bottom: 1rem;
	}
}

.call-info-block-title {
	background: $white;
	border: 1px solid $gray-200;
	border-top-left-radius: 0.25rem;
	border-top-right-radius: 0.25rem;
	padding: 0.5rem 1rem;
	position: relative;
	text-align: left;
	width: 100%;

	@include font-nunito($font-size: 1.25rem, $font-weight: 500);
}

.call-info-title-caret {
	align-items: center;
	bottom: 0;
	color: $gray-700;
	display: flex;
	padding-left: 0.5rem;
	padding-right: 0.5rem;
	position: absolute;
	right: 0;
	top: 0;
}

.call-info-card {
	background: $white;
	border: 1px solid $gray-400;
	border-radius: 0.25rem;
	box-shadow: $box-shadow-md;
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 1rem;
	padding: 1rem;
	width: 100%;

	@include font-roboto();
}

.call-action-bar-new {
	background: $white;
	border-bottom: 1px solid $gray-400;
	display: flex;
	flex-shrink: 0;
	height: 50px;
	justify-content: space-between;
	overflow-x: auto;
}

.play-recording-bar {
	background: $white;
	border-bottom: 1px solid $gray-400;
	display: flex;
	flex-shrink: 0;
	height: 39px;
	justify-content: space-between;
}

.audio-container {
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	position: relative;
	width: 100%;
	.control-button-play {
		background-color: transparent;
		border: none;
		border-radius: 4px;
		bottom: 7px;
		color: #4a5568;
		cursor: pointer;
		font-size: 16px;
		left: 5px;
		margin-right: 10px;
		padding-left: 5px;
		text-align: center;
		text-decoration: none;
		transition: background-color 0.3s;
		width: 25px;
	}
	.control-button-skip {
		background-color: transparent;
		border: none;
		border-radius: 4px;
		bottom: 7px;
		color: black;
		cursor: pointer;
		font-size: 16px;
		left: 37px;
		margin-right: 10px;
		text-align: center;
		text-decoration: none;
		transition: background-color 0.3s;
		width: 27px;
		z-index: 44;
	}
}
.duration {
	color: #4a5568;
	font-size: 12px;
	width: 110px;
}

.audio-controls {
	align-items: center;
	background-color: white;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
	display: flex;
	gap: 10px;
	padding: 0 20px;
	width: 100%;

	@include font-roboto();
}

.play-all-label {
	color: #4a5568;
	font-size: 15px;
	font-weight: 500;
	white-space: nowrap;
}

.audio-player {
	background-color: white;
	border: none;
	box-shadow: none;
	display: none;
	outline: none;
	overflow: hidden;
	width: 105%;
}

.audio-player-wrapper {
	align-items: center;
	display: flex;
	gap: 10px;
}

.audio-slider {
	appearance: none; /* Remove default styling */
	background: #cbd5e0; /* Slider track background color */
	border-radius: 5px;
	height: 2px; /* Slider track height */
	margin: 0 20px 0 10px;
	outline: none;
	transition: background 0.2s;
	width: 100%;
}

.audio-slider::-webkit-slider-thumb {
	appearance: none; /* Remove default styling */
	background-color: #4a5568; /* Slider thumb color */
	border-radius: 50%;
	cursor: pointer;
	height: 20px; /* Slider thumb height */
	transition: background 0.2s;
	width: 20px; /* Slider thumb width */
}

.audio-slider::-moz-range-thumb {
	appearance: none; /* Remove default styling */
	background-color: #4a5568;
	border: none;
	border-radius: 50%;
	cursor: pointer;
	height: 20px;
	outline: none;
	transition: background 0.2s;
	width: 20px;
}

.audio-slider:hover {
	background: #aaa; /* Change track color on hover */
}

.audio-slider::-webkit-slider-thumb:hover {
	background-color: #555; /* Change thumb color on hover */
}

.audio-player::-webkit-scrollbar {
	display: none;
}

.call-action-bar {
	display: flex;
	width: 100%;
}

.audio-controls {
	align-items: center;
	display: flex;
	width: 100%;
}

.audio-wrapper {
	height: 38px;
	left: 80px;
	position: absolute;
	width: 90%;
}

.audio-player {
	height: 38px;
	input {
		border: 2px solid red;
	}
}

.control-button {
	flex-shrink: 0;
}

.mute-skip-control {
	display: flex;
	gap: 10px;
	position: absolute;
	right: 20px;
}

.new-design-icon {
	height: 20px;
	width: 20px;
}

.new-design-icon-sm {
	height: 15px;
	width: 15px;
}

.multi-mrn-panel {
	max-height: 60vh;
	overflow: auto;
	overflow-x: hidden;
	hr.multi-mrn-container-border {
		margin-left: -20px;
		margin-right: -20px;
	}
}

.card-title-border {
	margin-bottom: 0 !important;
}

// Variables
$primary-color: #3498db;
$secondary-color: #2c3e50;
$background-color: #ecf0f1;
$font-color: #2c3e50;
$font-size-base: 18px;
$card-border-color: #cbd5e0;
$card-border-radius: 4px;
$card-padding: 20px;
$title-height: 52px;
$footer-height: 40px;
$card-title: #2d3748;
$card-label: #64738b;
$card-value-color: #4a5568;
$card-label-size: 16px;
$card-value-size: 18px;
$font-weight-Medium: 500;

// Mixins

@mixin flex-center {
	align-items: center;
	display: flex;
	justify-content: center;
}

@mixin transition($property, $duration: 0.3s, $ease: ease-in-out) {
	transition: $property $duration $ease;
}

// Container
.call-dashboard_container {
	display: flex;
	flex-wrap: wrap;
	gap: 20px;
	padding: 20px;
	width: 100%;

	@include font-roboto();
	.call-dashboard_container-left {
		width: calc(60% - 20px);

		@media (max-width: 1170px) {
			width: 100%;
		}
	}
	.call-dashboard_container-right {
		width: 40%;
		.card {
			margin-bottom: 70px !important;
		}

		@media (max-width: 1170px) {
			width: 100%;
		}
	}
}
.card {
	background: white;
	border: 1px solid $card-border-color;
	border-radius: $card-border-radius;
	box-shadow: $box-shadow;
	margin-bottom: 20px;
	&:last-child {
		margin-bottom: 0;
	}
	&-title {
		align-items: center;
		border-bottom: 1px solid $card-border-color;
		color: $card-title;
		display: flex;
		font-size: $font-size-base;
		font-weight: 500;
		height: $title-height;
		justify-content: space-between;
		padding: 0 $card-padding;
		&.multiline-title {
			height: auto;
			min-height: $title-height;
			padding: 10px 20px;
			.card-title_left {
				.card-title_left_name {
					margin-bottom: 5px;
				}
			}
		}
		&_left {
			align-items: center;
			display: flex;
			flex-wrap: wrap;
			&_name {
				width: 100%;
			}
			&_name-description {
				color: $card-value-color;
				font-size: 14px;
				line-height: 1;
			}
		}

		&_right {
			align-items: center;
			display: flex;
			gap: 15px;
			svg {
				cursor: pointer;
				height: 24px;
				width: 24px;
				&.multipleMRN {
					height: 34px;
					width: 34px;
				}
				&:hover {
				}
				&.accordion-icon {
					color: #4a5568;
					height: 20px;
					width: 20px;
				}
			}
		}
	}
	&-body {
		font-weight: $font-weight-Medium;
		margin-top: 10px;
		&.card-accordion-body {
			display: none;
			&.active {
				display: block;
			}
		}
		.multi-mrn_list {
			border-bottom: 1px solid $card-border-color;
			margin-bottom: 10px;
			.form-item {
				margin-bottom: 6px;
			}
			&:last-child {
				border-bottom: 1px solid transparent;
				margin-bottom: 0;
			}
		}
		.form-item {
			margin-bottom: 10px;
			padding: 0 20px;
			&__label {
				color: $card-label;
				display: block;
				font-size: $card-label-size;
				font-weight: 500;
				line-height: 1.2;
				margin-bottom: 0;
			}

			&__inputField {
				align-items: center;
				color: $card-value-color;
				display: flex;
				font-size: $card-value-size;
				word-break: break-word;

				&-input {
					align-items: center;
					border: 1px solid #cbd5e0;
					border-radius: 4px;
					display: flex;
					margin-top: 5px;
					min-height: 32px;
					padding: 0 10px;
					padding-right: 25px;
					position: relative;
					width: calc(100% - 35px);
					word-break: break-word;
					&.label-only {
						border: 0 solid transparent;
						margin-top: 0;
						padding: 0;
					}
				}
				.mrn-button {
					display: flex;
					justify-content: flex-end;
					width: 35px;
				}

				&-radio {
					display: flex;
					.radio-container {
						margin-right: 10px;
						.radio-label {
							margin-bottom: 0;
							margin-left: 10px;
						}
					}
				}

				&--editable {
					border: 0;
					flex: 1;
				}

				&--link {
					cursor: pointer;
				}

				&--nonEditable {
					border: 0;
					color: #4a5568;
					flex: 1;
					line-height: 1.5;
					&.editForm {
						background: #cbd5e0;
						border-radius: 4px;
						margin-top: 5px;
						max-width: calc(100% - 35px);
						padding: 2px 10px;
					}
				}
			}

			.clear-button {
				background: transparent;
				border: none;
				cursor: pointer;
				display: none;
				outline: none;
				position: absolute;
				right: 10px;
				top: 8px;
				z-index: 1;

				svg {
					stroke: #999;
					transition: stroke 0.2s;
				}

				&:hover svg {
					stroke: #333;
				}
			}

			.clear-button-dob {
				background: transparent;
				border: none;
				cursor: pointer;
				display: block;
				outline: none;
				position: absolute;
				right: 10px;
				top: 8px;
				z-index: 1;

				svg {
					stroke: #999;
					transition: stroke 0.2s;
				}

				&:hover svg {
					stroke: #333;
				}
			}

			&__audioPlayer {
				align-items: center;
				display: flex;
				justify-content: center;
				margin-left: 10px;
				padding: 0 10px;
				width: 25px;
				button {
					display: flex;
				}
				&.editForm {
					margin-top: 5px;
				}
				.new-design-icon {
					color: #4a5568;
					height: 22px;
					width: 22px;
				}

				audio-player {
					display: inline-block;
				}
			}

			&__inputField--editable:not(:empty) + .clear-button,
			&__inputField--editable:focus + .clear-button {
				display: block;
			}

			hr {
				border-top: 2px solid #cbd5e0;
				border-top-width: 2px;
			}
		}
		&.message-container {
			color: #4a5568;
			font-weight: 500;
			.button-container {
				align-items: flex-start;
				display: flex;
				.chip-set {
					width: calc(100% - 190px);
				}
			}
			.message-date {
				font-size: 14px;
				padding: 0 15px;
			}
			.call-message-edit {
				border: 1px solid #cbd5e0;
				font-size: 16px;
				margin: 5px 15px 0;
				padding: 10px;
				div {
					word-break: break-word;
				}
			}
			.action-container {
				align-items: center;
				display: flex;
				justify-content: right;
				width: 190px;
				.status-message {
					font-size: 12px;
				}
				.button-container {
					align-items: center;
					display: flex;
					.new-design-icon {
						color: #4a5568;
					}
					button {
						margin-left: 15px;
					}
				}
			}
		}
	}
	.card-history-list-container {
		min-height: 401px;
		overflow-x: hidden;
		overflow-y: auto;
	}
	.card-recording-list-container {
		min-height: 401px;
		overflow-x: hidden;
		overflow-y: auto;
	}
	.card-notes-list-container {
		min-height: 690px;
		overflow-x: hidden;
		overflow-y: auto;
	}
	&-list {
		align-items: flex-start;
		border-bottom: 1px solid $card-border-color;
		display: flex;
		padding-bottom: 0.5rem;
		padding-top: 0.5rem;
		width: 100%;
		&.note-list {
			flex-wrap: wrap;
			.card-list-container {
				flex-wrap: wrap;
			}
			.note_button-container {
				display: flex;
				gap: 15px;
				justify-content: flex-end;
				width: 120px;
				.new-design-icon {
					color: #4a5568;
					height: 20px;
					width: 20px;
				}
			}
			.note-list__title-section {
				display: flex;
				width: 100%;
			}
			.note-list__description-section {
				align-items: flex-end;
				display: flex;
				margin-top: 5px;
				width: 100%;
			}
			.content-item_description {
				width: calc(100% - 120px);
				word-break: break-word;
			}
		}

		.card-list-container {
			display: flex;
			padding: 0 20px;
			width: 100%;
			.content-item {
				color: #4a5568;
				font-size: 16px;
				width: calc(100% - 120px);
				word-break: break-word;
				&_title {
					font-weight: 500;
				}
				&_description {
				}
			}
			.date-container {
				width: 120px;
				.date {
					color: #64738b;
					font-size: 12px;
					margin-top: 0;
				}
			}
		}

		.icon-container {
			align-items: center;
			display: flex;
			justify-content: center;
			margin-top: 5px;
			padding-left: 0.5rem;
			width: 50px;

			.icon {
				color: #6200ee;
				font-size: 1.25rem;
				height: 20px;
				width: 21px;
			}
			&--small {
				align-items: center;
				display: flex;
				justify-content: center;
				margin-top: 3px;
				padding-left: 0.5rem;
				width: 50px;
				.icon {
					color: #6200ee;
					font-size: 1.25rem;
					height: 20px;
					width: 21px;
				}
			}
		}

		.content-container {
			align-items: flex-start;
			display: flex;
			font-size: 16px;
			justify-content: space-between;
			width: 100%;
			.summary-container {
				align-items: center;
				display: flex;
				padding-bottom: 0;
				padding-left: 0.5rem;
				padding-right: 0.5rem;
				width: calc(100% - 140px);

				.summary {
					color: #4a5568;
					word-break: break-word;
				}
			}

			.date-container {
				margin-top: 0;
				padding-right: 20px;
				width: 140px;

				.date {
					color: #64738b;
					font-size: 12px;
					font-weight: 400;
					margin-top: 0;
				}
			}
		}
		.recording-container {
			align-items: flex-start;
			display: flex;
			font-size: 16px;
			justify-content: space-between;
			width: 100%;
			.recording-detail-container {
				align-items: center;
				display: flex;
				padding: 0 20px;
				width: 100%;
				.recording-detail {
					align-items: center;
					display: flex;
					&_left {
						color: #4a5568;
						width: calc(100% - 90px);
						word-break: break-word;
					}
					&_right {
						color: #64738b;
						display: flex;
						font-size: 14px;
						font-weight: 500;
						justify-content: end;
						text-align: right;
						width: 90px;
						> span {
							margin-right: 10px;
						}
					}
				}
			}
		}
	}
	&-no-item-item {
		color: #4a5568;
		padding: 10px 20px;
	}
	&-footer {
		align-items: center;
		border-top: 1px solid $card-border-color;
		display: flex;
		height: $footer-height;
		justify-content: space-between;
		padding: 0 $card-padding;
		.new-design-icon {
			color: #4a5568;
			height: 20px;
			width: 20px;
		}
		&_left {
		}

		&_right {
			.card-footer_button-container {
				align-items: center;
				display: flex;
				gap: 15px;
			}
			.card-footer_button {
				&:disabled {
					cursor: progress;
				}
			}
		}
	}
}
