// Call Inbox
//
// @author Erik Galloway <egalloway@claruscare.com>
// @author Alejandro Sanchez <asanchez@claruscare.com>
//
// Inbox
// Header
// Title
// Item
// Timestamp
// Section
// Item title
// Item subtitle (dob & phone)
// Chips
// Group
// Group title

// Inbox

// Variables
$card-bg-color: #fff;
$card-bg-hover: rgba(98, 0, 238, 0.02);
$card-bg-active: rgba(98, 0, 238, 0.03);
$card-border-color: #cbd5e0;
$primary-text-color: #4a5568;
$text-gray-lighter: #8d9db5;
$text-gray-light: #64738b;
$tag-bg-color: #f5f5f5;
$card-group-title-bg: #edf2f7;

.inbox {
	background: $white;
	display: none;
	flex-direction: column;
	height: 100%;
	max-width: $inbox-width-sm;
	overflow-x: hidden;
	overflow-y: auto;
	padding-bottom: 3rem;
	width: $inbox-width-sm;

	@include browser-font-smooth();

	@include media-breakpoint-up(md) {
		&.inbox-narrow {
			border-right: 1px solid $gray-400;
			display: flex;
			max-width: 250px;
		}
	}

	&:not(.inbox-narrow) {
		@include media-breakpoint-up(md) {
			border-right: 1px solid $gray-400;
			display: flex;
			max-width: $inbox-width-md;
		}

		@include media-breakpoint-up(lg) {
			max-width: $inbox-width-lg;
		}

		@include media-breakpoint-up(xl) {
			max-width: $inbox-width-xl;
		}
	}

	:not(.calls-show) > &:not(.inbox-narrow) {
		display: flex;

		@include media-breakpoint-down(sm) {
			margin-top: $navbar-height;
		}
	}
}

// Header

.inbox-header {
	background-color: $white;
	border-bottom: 1px solid $gray-400;
	margin-bottom: -1px;
	padding-bottom: 0.5rem;
	width: 100%;

	&.pages {
		min-height: 125px;
	}
}

.inbox-header-subtitle {
	align-items: center;
	color: $gray-700;
	display: flex;
	margin-left: auto;

	@include font-roboto($font-size: 0.875rem, $font-weight: 600);
	@include letter-spacing(0.1, 0.875rem);
}

// Title

.inbox-title {
	align-items: center;
	border-bottom: 1px solid $gray-400;
	border-top: none;
	color: $gray-700;
	display: flex;
	justify-content: space-between;
	min-height: $navbar-height;
	padding-left: 1.5rem;
	padding-right: 1.5rem;

	@include font-roboto($font-size: 1.1rem, $font-weight: 400);
	@include letter-spacing(0.35, 1.1rem);

	> .inbox-title-label {
		margin: 0 auto;
		overflow: hidden;
		text-align: center;
	}

	@include media-breakpoint-up(md) {
		height: $navbar-height;
	}
}

.inbox-title-badge {
	align-items: center;
	background: rgba($purple, 0.12);
	border-radius: 16px;
	color: $purple;
	display: inline-flex;
	font-size: 1rem;
	font-weight: 500;
	justify-content: center;
	min-width: 2rem;
	padding: 0 0.5rem;

	.new-notification-ping {
		align-items: center;
		color: $red-500;
		display: flex;
		font-size: 0.75rem;
		justify-content: center;
		opacity: 0.4;
		position: absolute;
		right: -0.3rem;
		top: -3px;
	}

	.new-notification {
		align-items: center;
		color: $red-600;
		display: flex;
		font-size: 0.75rem;
		justify-content: center;
		position: absolute;
		right: -0.3rem;
		top: -3px;
	}
}

// Item

.inbox-item {
	background: $white;
	border-top: 1px solid $gray-400;
	display: flex;
	flex-direction: column;
	height: 121px;
	justify-content: space-between;
	min-height: 121px;
	position: relative;

	@include font-roboto;

	&.active {
		background: rgba($purple, 0.03);
	}

	.inbox-group-title + & {
		margin-top: 0;
	}

	.inbox-group:last-child &:last-child {
		border-bottom: 1px solid $gray-400;
	}
}

// Timestamp

.inbox-item-timestamp {
	color: $gray-600;
	margin-left: auto;
	margin-top: 0.3rem;
	padding: 0.25rem;
	position: absolute;
	right: 0;
	text-align: center;
	text-transform: lowercase;
	top: 50%;
	transform: translateY(-50%);
	width: 4.5rem;

	@include letter-spacing(0.25, 0.875rem);
	@include font-roboto($font-size: 0.85rem, $font-weight: 500);

	@include media-breakpoint-only(md) {
		top: 0;
		transform: none;
	}
}

// Section

.inbox-item-section {
	align-items: flex-start;
	display: flex;
	flex-flow: column;
	padding-left: 3rem;
	padding-right: 1rem;

	&:first-child {
		padding-top: 0.5rem;
	}

	+ .inbox-item-section {
		padding-top: 1rem;
	}

	&:last-child {
		padding-bottom: 1rem;
	}

	.new-call,
	.dismissed-call,
	.provider,
	.calendar-icon,
	.complete-call {
		font-size: 0.825rem;
		width: 16.5px;
		z-index: 2;

		> svg {
			fill: currentColor;
		}
	}
}

.inbox-item-section .new-call {
	color: $purple-600;
	cursor: pointer;
	margin-left: -2rem;
	margin-right: 1rem;
}

.inbox-item-section .calendar-icon,
.inbox-item-section .provider,
.inbox-item-section .complete-call {
	color: $green-600;
	cursor: pointer;
	margin-left: -2rem;
	margin-right: 1rem;
}

.inbox-item-section .dismissed-call {
	color: $purple-600;
	cursor: pointer;
	margin-left: -2rem;
	margin-right: 1rem;
}

.inbox-item-section .calendar-icon {
	color: $blue-600;
}

// Item title

.inbox-item-title {
	align-items: center;
	color: $gray-700;
	display: flex;
	font-size: 1.1rem;
	font-weight: 500;
	white-space: nowrap;

	@include font-roboto();

	> svg {
		font-size: 1rem;
		font-weight: 400;
	}

	@include hover-focus {
		color: #70777d;
		outline: 0;
		text-decoration: none;

		&::after {
			background: rgba($purple, 0.02);
		}
	}
}

a.inbox-item-title::after {
	background-color: rgba(0, 0, 0, 0);
	bottom: 0;
	content: '';
	left: 0;
	pointer-events: auto;
	position: absolute;
	right: 0;
	top: 0;
	z-index: 1;
}

// Item subtitle (dob & phone)

.inbox-item-subtitle {
	color: $gray-500;
	display: flex;
	font-size: 0.9375rem;
	font-weight: 500;
	justify-content: space-between;
	line-height: 1.375rem;
	margin: 0.25rem 0;

	@include letter-spacing(0.1, 0.9375rem);

	> .inbox-dob-container {
		display: none;

		@include media-breakpoint-up(sm) {
			display: flex;

			> .call-spacer {
				margin: 0 0.5rem;
			}

			> .call-dob,
			> .call-phone {
				align-items: center;
				max-width: 150px;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
		}
	}
}

.inbox-item-message {
	color: $gray-500;
	font-size: 0.875rem;
	font-weight: 500;
	line-height: 1.375rem;
	margin: 0.25rem 0;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	width: 100%;

	@include letter-spacing(0.1, 0.9375rem);
}
// Chips

.inbox-item-section > .chip-set {
	margin-left: -0.25rem;
	margin-right: -0.25rem;
}

// Group

.inbox-group {
	border-top: 1px solid $gray-400;
}

// Group title

.inbox-group-title {
	background: $card-group-title-bg;
	color: $primary-text-color;
	font-size: 18px;
	font-weight: 500;
	overflow-y: hidden;
	padding: 1rem;
	text-transform: uppercase;

	@include font-roboto;
	@include letter-spacing(1.25, 1rem);

	> svg {
		margin-right: 1rem;
	}

	.inbox-item + & {
		border-top: 1px solid $card-border-color;
	}

	&.collapsable {
		align-items: center;
		cursor: pointer;
		display: flex;
		justify-content: space-between;
	}
}

.draggable-card {
	cursor: move;
}

.call-card {
	background-color: $card-bg-color;
	border-top: 1px solid $card-border-color;
	color: $primary-text-color;
	display: flex;
	flex-wrap: wrap;
	padding: 10px 10px 5px;
	width: 100%;

	@include font-roboto;
	> a {
		color: $primary-text-color;
		text-decoration: none;
		width: 100%;
	}

	&:hover {
		background-color: $card-bg-hover;
	}

	&.active {
		background-color: $card-bg-active;
	}

	.call-card__patient-details {
		width: 100%;
		.call-card__title {
			align-items: flex-start;
			display: flex;
			justify-content: space-between;
			width: 100%;
			word-break: break-word;

			.call-card__icon-container {
				align-items: center;
				display: flex;
				height: 20px;
				justify-content: center;
				margin-top: 5px;
				width: 20px;
				svg {
					height: 18px;
					width: 18px;
				}
				.new-call,
				.dismissed-call,
				.provider,
				.calendar-icon,
				.complete-call,
				.call-transfer-call {
					cursor: pointer;
					> svg {
						fill: currentColor;
					}
				}
				.new-call {
					color: $purple-600;
				}

				.calendar-icon,
				.provider,
				.complete-call {
					color: $green-600;
				}
				.dismissed-call {
					color: $purple-600;
				}
				.calendar-icon {
					color: $blue-600;
				}

				.call-transfer-call {
					color: $text-gray-light;
				}
			}

			.call-card__title-container {
				font-size: 18px;
				font-weight: 500;
				padding-left: 10px;
				padding-right: 10px;
				width: calc(100% - 40px);
			}
		}

		.call-card__phone,
		.call-card__MRN {
			font-size: 15px;
			font-weight: 500;
		}

		.call-card__phone {
			color: $text-gray-lighter;
		}

		.call-card__MRN {
			color: $text-gray-light;
		}
	}

	.call-card__tag-time-details {
		align-items: flex-end;
		display: flex;
		margin-top: 15px;
		width: 100%;

		.call-card__tag-container {
			display: flex;
			flex-wrap: wrap;
			width: calc(100% - 60px);
			.chip {
				margin-bottom: 5px;
				margin-right: 5px;
				&:last-child {
					margin-right: 0;
				}
				&:not([class*='text-']) {
					color: $text-gray-light;
				}
				&:not([class*='bg-']) {
					border-color: $card-border-color;
				}
			}
		}

		.call-card__time-container {
			color: $text-gray-light;
			font-size: 13px;
			font-weight: 500;
			margin-bottom: 5px;
			width: 60px;
		}
	}
}
