// Alert (snackbar)
//
// @author Erik Galloway <egalloway@claruscare.com>
//
// Container
// Snackbar
// Snackbar text
// Snackbar action
// Snackbar button
// Snackbar icon
// Color variations
// Animation

// Container
.snackbar-container {
	bottom: 0;
	display: flex;
	flex-flow: column-reverse;
	height: auto;
	max-width: 100%;
	position: fixed;
	right: 0;
	width: auto;
	z-index: $zindex-popover;
}

// Snackbar
.snackbar {
	align-items: center;
	background: $snackbar-bg;
	border-radius: $snackbar-border-radius;
	bottom: 1.5rem;
	box-shadow: $snackbar-shadow;
	color: $white;
	display: flex;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	justify-content: space-between;
	max-width: 100%;
	padding: 0 1.5rem;
	position: relative;
	right: 1.5rem;
	width: 33rem;

	@include font-roboto();

	@include media-breakpoint-only(xs) {
		padding: 0 1rem;
		right: 0;
		width: 20rem;
	}

	&:not(:first-child) {
		margin-bottom: 1.5rem;
	}

	&.snackbar-slide-leave-active {
		right: 0;
	}
}

// Snackbar text
.snackbar-text {
	margin-right: auto;
	padding: 1rem 0;
}

// Snackbar action
.snackbar-action {
	margin-left: 1.5rem;
}

// Snackbar button
.snackbar-btn {
	background: transparent;
	border: 0;
	color: $white;
	font-size: 0.875rem;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	font-weight: 500;
	letter-spacing: 0.08929rem;
	line-height: 2.25rem;
	outline: none;
	pointer-events: auto;
	text-decoration: none;
	text-transform: uppercase;
	transition: opacity 0.3s 0ms cubic-bezier(0.4, 0, 1, 1);
	user-select: none;

	@include font-roboto(500);

	&:hover {
		cursor: pointer;
	}

	@include hover-focus {
		outline: 0;
	}
}

// Snackbar icon
.snackbar-icon {
	font-size: 1.75rem;
	height: auto;
	margin-right: 1rem;
	min-width: 1.6rem;
}

// Color variation - error
.error {
	.snackbar-btn,
	.snackbar-icon {
		color: $snackbar-error-color;
	}
}

// Color variation - success
.success {
	.snackbar-btn,
	.snackbar-icon {
		color: $snackbar-success-color;
	}
}

// Color variation - warning
.warning {
	.snackbar-btn,
	.snackbar-icon {
		color: $snackbar-warning-color;
	}
}

// Animation

@include make-animation('snackbar-slide', 'slideInRight', 'slideOutRight');
