// Group coverage window
//
// @author Alejandro Sanchez <asanchez@claruscare.com>
//
// Container
// Header
// Body

// Container
.group-coverage-window {
	color: $gray-700;
	min-width: 10rem;
	padding: 1rem;
	position: absolute;
	width: 25rem;
	z-index: $zindex-popover;
}

// Header
.group-coverage-header {
	background-color: $gray-300;
	border: 1px solid $gray-400;
	border-bottom: none;
	display: flex;
	padding: 1rem;
	z-index: $zindex-view-more;

	@include letter-spacing(1.5, 0.875rem);
	@include font-roboto($font-size: 1rem, $font-weight: 500);
}

// Body
.group-coverage-body {
	background-color: $white;
	border: 1px solid $gray-400;
	padding: 0.5rem;
}
