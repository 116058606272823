// Profile
//
// @author Alejandro Sanchez <asanchez@claruscare.com>
//
// View
// Container
// Profile section
// Address section
// Address title
// Address icon
// Form

// View
.profile-show {
	display: flex;
	flex-direction: column;
	overflow: auto;
	padding: 1rem;
	width: 100%;
}

.profile-name-title {
	margin-bottom: 1rem;
	margin-top: $navbar-height;
	padding: 1rem;

	@include font-roboto($font-size: 1.5rem);
}

.profile-header-btn {
	align-items: center;
	border-radius: 50%;
	color: $purple;
	display: flex;
	flex-direction: column;
	height: 50px;
	justify-content: center;
	margin-bottom: 1rem;
	margin-top: $navbar-height;
	padding: 1rem;
	transition: all 0.4s;
	width: 50px;

	@include font-roboto($font-size: 1.5rem);

	@include hover-focus {
		outline: 0;
		text-decoration: none;
	}
}

// Container
.profile-container {
	display: flex;
	flex-wrap: wrap;
	width: 100%;

	@include media-breakpoint-up(lg) {
		flex-wrap: wrap;
	}
}

// Profile section
.profile-section {
	width: 100%;

	@include media-breakpoint-up(lg) {
		margin-left: 20px;
		margin-right: 10px;
		width: calc(50% - 30px);
	}
}

// Address section
.address-section {
	width: 100%;

	@include media-breakpoint-up(lg) {
		margin-left: 10px;
		margin-right: 20px;
		width: calc(50% - 30px);
	}
}

// Address title
.address-name-title {
	display: flex;
	justify-content: space-between;
	margin-bottom: 1rem;
	padding: 1rem;

	@include font-roboto($font-size: 1.5rem);
}

.voicemail-section {
	width: 100%;

	@include media-breakpoint-up(lg) {
		margin-left: 20px;
		margin-right: 20px;
		width: calc(100% - 40px);
	}
}

// Voicemail title
.voicemail-name-title {
	display: flex;
	justify-content: space-between;
	margin-bottom: 1rem;
	padding: 1rem;

	@include font-roboto($font-size: 1.5rem);
}

.form-save-button-container {
	width: 100%;

	@include media-breakpoint-up(lg) {
		padding: 0 20px;
		width: 100%;
	}
}

// Address icon
.address-icon {
	color: $purple;
}

// Form
.profile-form {
	background: $white;
	border: 1px solid $gray-400;
	border-radius: 0.25rem;
	margin-bottom: 1rem;
	padding: 1rem;
}

.voicemail-section-single-line-details {
	display: flex;
	flex-wrap: wrap;
	width: 100%;

	@include media-breakpoint-up(lg) {
		width: 100%;
	}
}

.form-group-voicemail-section-single-line-details {
	padding: 3px;
	width: 33.33%;
}

.nonEditable-text {
	appearance: none;
	background-color: $gray-400;
	color: $gray-800;
	height: 49px;
	justify-content: center;
	letter-spacing: 0.009375em;
	padding: 10px;
	width: 100%;

	&:focus {
		border: 1px solid $gray-400;
		box-shadow: $box-shadow;
		outline: none;
	}

	@include font-roboto;

	&.has-trailing-icon {
		padding-right: 1.5rem;
	}
}

.profile-section-single-line-details {
	display: flex;
	flex-wrap: wrap;
	width: 100%;

	@include media-breakpoint-up(lg) {
		width: 100%;
	}
}
.form-group-profile-section-single-line-details {
	padding: 3px;
	width: 50%;
}

.relative {
	position: relative;
}

.absolute {
	position: absolute;
}
