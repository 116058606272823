/* stylelint-disable max-line-length */
// Buttons
//
// @author Erik Galloway <egalloway@claruscare.com>
//
// @example
//	<button class="btn {btn-raised|btn-flat|btn-outlined} {btn-outline-{success|danger|warning|info|primary|secondary|light|dark}}">
//		<span class="btn-label">Button Text</span>
//		<div class="btn-ripple"></div>
//	</button>
//
// Text (no border): <button class="btn"></button>
// Text (colorized w/out border): <button class="btn btn-{success|danger|warning|info|primary|secondary|light|dark}"></button>
// Raised: <button class="btn btn-raised"></button>
// Raised (colorized): <button class="btn btn-raised btn-{success|danger|warning|info|primary|secondary|light|dark}"></button>
// Flat: <button class="btn btn-flat"></button>
// Flat (colorized): <button class="btn btn-flat btn-{success|danger|warning|info|primary|secondary|light|dark}"></button>
// Outlined: <button class="btn btn-outlined"></button>
// Outlined (colorized): <button class="btn btn-outlined btn-outline-{success|danger|warning|info|primary|secondary|light|dark}"></button>
//
// Button
// Icon
// Elevations
// Elevations: raised
// Elevations: outlined
// Ripple
// Variants
// Icon button

// Button
.btn {
	align-items: center;
	border: 0;
	border-radius: 0.25rem;
	box-sizing: border-box;
	display: inline-flex;
	font-size: 0.875rem;
	height: 36px;
	justify-content: center;
	line-height: 2.25rem;
	min-width: 4rem;
	outline: none;
	overflow: visible;
	padding: 0.5rem;
	position: relative;
	text-decoration: none;
	text-transform: uppercase;
	user-select: none;
	vertical-align: middle;

	@include font-roboto($font-size: 0.875rem, $font-weight: 500);
	@include letter-spacing(1.25, 0.875rem);

	&::-moz-focus-inner {
		border: 0;
		padding: 0;
	}

	&:active {
		outline: none;
	}

	&:hover {
		cursor: pointer;
		text-decoration: none;
	}

	&:focus {
		outline: none;
	}

	&:disabled {
		background-color: transparent;
		color: rgba($black, 0.37);
		cursor: default;
		pointer-events: none;
	}

	&:not(:disabled) {
		background-color: transparent;
		color: $purple;
	}

	&.btn-sm {
		min-width: 2rem;
	}
}

// Icon

.btn-icon {
	display: inline-block;
	fill: currentColor;
	font-size: 1rem;
	height: 1rem;
	margin-left: 0;
	margin-right: 0.5rem;
	vertical-align: top;
	width: 1rem;

	&:last-child {
		margin-right: 0;
	}
}

.btn-label + .btn-icon {
	margin-left: 0.5rem;
	margin-right: 0;
}

.btn-raised .btn-icon,
.btn-flat .btn-icon,
.btn-outlined .btn-icon {
	margin-left: -0.25rem;
	margin-right: 0.5rem;
}

.btn-raised .btn-label + .btn-icon,
.btn-flat .btn-label + .btn-icon,
.btn-outlined .btn-label + .btn-icon {
	margin-left: 0.5rem;
	margin-right: -0.25rem;
}

// Elevations

.btn-flat,
.btn-raised {
	padding: 0 1rem;

	&:disabled {
		background-color: rgba($black, 0.12);
		color: rgba($black, 0.37);
	}

	&:not(:disabled) {
		background-color: $purple;
		color: $white;
	}
}

// Elevations: raised

.btn-raised {
	box-shadow: $btn-raised-box-shadow;
	transition: $btn-raised-transition;

	@include hover-focus {
		box-shadow: $btn-raised-hover-box-shadow;
	}

	&:active {
		box-shadow: $btn-raised-active-box-shadow;
	}

	&:disabled {
		box-shadow: $btn-raised-disabled-box-shadow;
	}
}

// Elevations: outlined

%button-outline {
	border-style: solid;
	border-width: 1px;
	padding: 0 0.9375rem;

	.btn-outlined .btn-ripple {
		border: 1px solid transparent;
		left: -1px;
		top: -1px;
	}

	&:disabled {
		border-color: rgba(0, 0, 0, 0.37);
	}
}

.btn-outlined {
	@extend %button-outline;

	&:not(:disabled) {
		border-color: $purple;
	}
}

// Ripple
.btn .btn-ripple {
	border-radius: 0.25rem;
	box-sizing: content-box;
	height: 100%;
	overflow: hidden;
	position: absolute;
	width: 100%;

	&::before,
	&::after {
		border-radius: 50%;
		content: '';
		opacity: 0;
		pointer-events: none;
		position: absolute;
	}

	&::before {
		transform: scale(1);
		transition: opacity 15ms linear, background-color 15ms linear;
		z-index: 1;
	}

	&::after {
		height: 100%;
		left: 0;
		top: 0;
		transform: scale(0);
		transform-origin: center center;
		width: 100%;
	}
}

.btn.btn-deactivated .btn-ripple::after {
	transform: translate(0) scale(1);
}

.btn .btn-ripple::before,
.btn .btn-ripple::after {
	background-color: $purple;
	height: 200%;
	left: calc(50% - 100%);
	top: calc(50% - 100%);
	width: 200%;
}

.btn:hover .btn-ripple::before {
	opacity: 0.04;
}

.btn:focus .btn-ripple::before {
	opacity: 0.12;
	transition-duration: 75ms;
}

.btn:not(.btn-outlined) .btn-ripple {
	left: 0;
	top: 0;
}

.btn-raised .btn-ripple::before,
.btn-raised .btn-ripple::after,
.btn-flat .btn-ripple::before,
.btn-flat .btn-ripple::after {
	background-color: $white;
}

.btn-raised:hover .btn-ripple::before,
.btn-flat:hover .btn-ripple::before {
	opacity: 0.08;
}

// Variants

@each $color, $value in $theme-colors {
	.btn-#{$color} {
		&:not(:disabled) {
			color: $value;
		}

		&.btn-flat:not(:disabled),
		&.btn-raised:not(:disabled) {
			background-color: $value;
			color: color-yiq($value);
		}

		.btn-ripple::before,
		.btn-ripple::after {
			background-color: $value;
		}
	}
}

@each $color, $value in $theme-colors {
	.btn-outline-#{$color} {
		@extend %button-outline;

		&:not(:disabled) {
			border-color: $value;
			color: $value;
		}

		.btn-ripple::before,
		.btn-ripple::after {
			background-color: $value;
		}

		.btn-icon {
			margin-left: -0.25rem;
			margin-right: 0.5rem;
		}

		.btn-label + .btn-icon {
			margin-left: 0.5rem;
			margin-right: -0.25rem;
		}
	}
}

// Icon button

.btn-icon-button {
	background-color: transparent;
	border: 0;
	box-sizing: border-box;
	color: inherit;
	cursor: pointer;
	display: inline-flex;
	fill: currentColor;
	font-size: 24px;
	height: 48px;
	outline: none;
	outline: 0;
	padding: 12px;
	position: relative;
	text-decoration: none;
	user-select: none;
	width: 48px;

	&:focus {
		outline: 0;
	}

	&::after,
	&::before {
		background-color: $black;
		border-radius: 50%;
		content: '';
		height: 100%;
		left: 0%;
		opacity: 0;
		pointer-events: none;
		position: absolute;
		top: 0%;
		width: 100%;
	}

	&::before {
		transition: opacity 15ms linear, background-color 15ms linear;
		z-index: 1;
	}

	&::after {
		transition: opacity 0.15s linear;
	}

	&:hover::before {
		opacity: 0.04;
	}

	&:focus::before {
		opacity: 0.12;
		transition-duration: 75ms;
	}

	> svg {
		height: 1.5rem;
		width: 1.5rem;
	}
}
