// Days of week selector
//
// @author Alejandro Sanchez <asanchez@claruscare.com>
//

// Container
// Item

// Container
.days-of-week-container {
	display: flex;
	flex-direction: row;
}

// Item
.days-of-week-item {
	align-items: center;
	background: $gray-200;
	border: 1px solid $gray-400;
	border-radius: 0.25rem;
	box-shadow: $box-shadow-sm;
	color: $gray-900;
	cursor: pointer;
	display: flex;
	height: 1.75rem;
	justify-content: center;
	margin-right: 0.75rem;
	width: 1.75rem;

	@include letter-spacing(0.7, 0.875rem);
	@include font-roboto($font-size: 0.875rem);

	&.active {
		background: $blue-200;
		border: 1px solid $blue-400;
		color: $blue-900;

		@include font-roboto(700, $font-size: 0.875rem);
	}
}
