// Chip
//
// @author Alejandro Sanchez <asanchez@claruscare.com>
//
// Chip
// Chip set
// Text
// Icon

// Chip
.chip {
	align-items: center;
	appearance: none;
	border-radius: 1rem;
	border-style: solid;
	border-width: 1px;
	display: inline-flex;
	flex-direction: row;
	font-size: 0.875rem;
	font-weight: 500;
	height: 2rem;
	line-height: 1.25rem;
	outline: none;
	overflow: hidden;
	padding: 0 0.75rem;
	position: relative;
	text-transform: uppercase;

	@include letter-spacing(0.5, 0.75rem);
	@include font-roboto();

	&.condensed {
		font-size: 12px;
		height: 22px;
		padding-left: 0.5rem;
		padding-right: 0.5rem;
	}

	&:not([class*='bg-']) {
		background-color: transparent;
		border-color: $gray-300;
	}

	&:not([class*='text-']) {
		color: $gray-600;
	}

	&[role='button'] {
		cursor: pointer;
	}

	&[role='button']:not([class*='bg-']):hover {
		background: $gray-200;
	}
}

// Chip set

.chip-set {
	align-items: center;
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	> .chip {
		margin: 0.25rem;

		&:first-child {
			margin-left: 0;
		}

		&:last-child {
			margin-right: 0;
		}
	}
}

// Text

.chip-text {
	white-space: nowrap;
}

// Icon

.chip-icon {
	font-size: 1.125rem;
	height: 1.25rem;
	margin: -0.25rem 0.5rem -0.25rem -0.25rem;

	.condensed & {
		font-size: 1rem;
		height: 1.125rem;
		margin: -0.15rem 0.35rem -0.15rem -0.15rem;
	}
}

// Remove Icon

.chip-remove {
	border: 1px solid transparent;
	border-radius: 50%;
	cursor: pointer;
	font-size: 1.125rem;
	height: 1rem;
	margin: -0.15rem 0.15rem;
	padding: 0 0.1rem;

	.condensed & {
		font-size: 0.5rem;
		height: 1.125rem;
		margin: -0.15rem -0.15rem -0.15rem 0.35rem;
		padding: 0.1rem;
		width: 1.125rem;
	}

	&:hover {
		background: $gray-300;
		border: 1px solid $gray-300;
		color: $gray-600;
	}
}
