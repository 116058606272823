// Call type
//
// @author Alejandro Sanchez <asanchez@claruscare.com>
//
// Component
// Icon
// Dropdown
// Header
// Container
// Option item
// Toggle items
// Submit

// Component
.call-type {
	height: 100%;
	position: relative;
}

// Icon
.call-type-icon {
	align-items: center;
	display: inline-flex;
	justify-content: center;
	margin-left: 0.5rem;
}

.call-type-dropdown {
	background-color: $white;
	border: 1px solid rgba(0, 0, 0, 0.15);
	box-shadow: $box-shadow-xl;
	display: none;
	margin: 0.125rem 0 0;
	max-height: 18em;
	min-height: 10em;
	min-width: 10rem;
	position: fixed;
	width: 25%;
	z-index: $zindex-dropdown;

	&.show {
		display: flex;
	}
}

// Dropdown
.call-type-dropdown-list {
	background-clip: padding-box;
	background-color: $white;
	color: $gray-800;
	display: flex;
	flex-direction: column;
	font-size: 1rem;
	list-style: none;
	margin-bottom: 3.9375rem;
	overflow-y: auto;
	padding-bottom: 0.5rem;
	text-align: left;
	width: 100%;
}

// Header

.call-type-header {
	color: #6c757d;
	color: $gray-800;
	display: block;
	white-space: nowrap;

	@include font-roboto($font-size: 0.875rem, $font-weight: 500);
	@include letter-spacing(1.25, 0.875rem);
}

// Container

.call-type-container {
	padding: 1rem;
}

// Option item

.call-type-item {
	align-items: center;
	background-color: transparent;
	border: 0;
	clear: both;
	color: $gray-800;
	cursor: pointer;
	cursor: pointer;
	display: flex;
	font-weight: 400;
	justify-content: flex-start;
	padding: 0.25rem;
	text-align: inherit;
	white-space: nowrap;
	width: 100%;

	&[role='option'] {
		padding-bottom: 0;
		padding-top: 0;
	}

	&[role='option']:hover {
		background-color: $gray-200;
		color: $gray-800;
		text-decoration: none;
	}
}

// Submit

.call-type-submit {
	align-items: center;
	background-color: $gray-100;
	border-top: 1px solid $gray-200;
	bottom: 0;
	display: flex;
	height: 3.9375rem;
	left: 0;
	padding: 0.5rem 1.75rem;
	position: absolute;
	right: 0;
}
