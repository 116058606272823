// Multiselect input
//
// @author Erik Galloway <egalloway@claruscare.com>

.multiselect-item-content {
	align-items: center;
	color: $gray-800;
	display: flex;
	justify-content: space-between;

	@include letter-spacing(1, 0.9375rem);
	@include font-roboto($font-size: 0.9375rem, $font-weight: 500);
}

.multiselect__element > .multiselect__option {
	&.multiselect__option--highlight {
		background-color: rgba(98, 0, 238, 0.12);
		color: $purple-700;
	}

	&.multiselect__option--highlight.multiselect__option--selected {
		background-color: rgba(98, 0, 238, 0.12);
		color: $purple-700;
		font-weight: 500;
	}
}

.partner-select-count-badge {
	align-items: center;
	background: rgba(98, 0, 238, 0.12);
	border-radius: 16px;
	color: $purple;
	display: inline-flex;
	font-size: 1rem;
	font-weight: 500;
	height: 1.5rem;
	justify-content: center;
	min-width: 2rem;
	padding: 0 0.5rem;
}
