// Form - Radio Button
//
// @author Alejandro Sanchez <asanchez@claruscare.com>
//
// Wrapper
// Input
// Label

// Wrapper

.form-radio {
	display: block;
	padding-left: 1.25rem;
	position: relative;
}

// Input

.form-radio-input {
	margin-left: -1.25rem;
	margin-top: 0.4375rem;
	position: absolute;
}

// Label

.form-radio-label {
	color: $gray-700;
	margin-bottom: 0;

	@include letter-spacing(0.7, 0.875rem);
	@include font-roboto($font-size: 0.875rem);
}
