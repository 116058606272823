// Forms
//
// @author Alejandro Sanchez <asanchez@claruscare.com>
//
// Group
// Label
// Input
// Errors
// Plaintext
// Icons

// Group
.form-group {
	display: flex;
	flex-direction: column;
	margin-bottom: 1.25rem;
}

// Label
.form-label {
	color: $gray-700;
	margin-bottom: 0.5rem;

	@include letter-spacing(0.7, 0.875rem);
	@include font-roboto($font-size: 0.875rem);
}

label.form-label {
	cursor: pointer;
}

// Input
.form-input {
	appearance: none;
	border: 1px solid $gray-300;
	border-radius: 0.25rem;
	color: $gray-800;
	letter-spacing: 0.009375em;
	padding: 0.75rem 1rem;
	width: 100%;

	&:focus {
		border: 1px solid $gray-400;
		box-shadow: $box-shadow;
		outline: none;
	}

	@include font-roboto;

	&.has-trailing-icon {
		padding-right: 1.5rem;
	}
}

// Plaintext

.form-plaintext {
	color: $gray-700;
	padding: 0.75rem 1rem;

	@include font-roboto;
}

// Errors

.form-error {
	color: $red-500;
	display: flex;
	margin-top: 0.5rem;

	@include font-roboto($font-size: 0.75rem, $font-weight: 500);
	@include letter-spacing(0.4, 0.75rem);
}

// Icons

.trailing-icon {
	bottom: 0.75rem;
	cursor: default;
	font-size: 1.25rem;
	line-height: 1.5rem;
	pointer-events: none;
	position: absolute;
	position: absolute;
	right: 0.5rem;
}

.tag-container {
	align-items: center;
	border: 1px solid #cbd5e0;
	border-radius: 0.5rem;
	display: flex;
	flex-wrap: wrap;
	min-height: 2.5rem;
	padding: 0.5rem 0.75rem;
	width: 100%;
}

.tag-container.empty {
	border-radius: 0.5rem;
	min-height: 2.5rem;
	padding: 0.5rem 0.75rem;
}

.tag {
	background-color: #e2e8f0;
	border-radius: 10px;
	color: #2d3748;
	margin: 0.25rem;
	padding: 0.25rem 0.5rem;
}

.tag .close {
	cursor: pointer;
	margin-left: 0.5rem;
}

.form-input {
	border: 1px solid #cbd5e0;
	border-radius: 0.5rem;
	margin-top: 0.5rem;
	padding: 0.5rem 1rem;
}

.input-tag-container {
	align-items: center;
	border-radius: 0.5rem;
	display: flex;
	position: relative;
	width: 100%;
}

.form-input-fallback_email {
	border: none;
	flex: 1;
	min-width: 150px;
	padding: 0.5rem 0;
}

.form-input-fallback_email:focus {
	outline: none;
}

.form-group-title-new {
	margin-bottom: 0 !important;
	margin-left: 4px !important;
}

.settings-input {
	border: 1px solid #cbd5e0;
	border-radius: 0.5rem;
	height: 42px !important;
	margin-top: 0.5rem;
	padding: 0.5rem 1rem !important;
}

.suggestions {
	background: white;
	border: 1px solid #ccc;
	list-style: none;
	margin: 0 0 2px;
	max-height: 150px;
	overflow-y: auto;
	padding: 0;
	position: absolute;
	width: 100%;
}

.suggestions li {
	cursor: pointer;
	padding: 8px;
}

.suggestions li:hover {
	background: #f0f0f0;
}

.suggestions-list {
	list-style-type: none;
	margin: 0;
	padding: 0;
}

.suggestions-list li {
	cursor: pointer;
	padding: 5px;
}

.suggestions-list li:hover {
	background-color: #eee;
}

.email-container {
	overflow: visible;
	position: relative;
}

.email-suggestions {
	background: white;
	border: 1px solid #ccc;
	border-radius: 0.5rem;
	border-top: none;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
	left: 0;
	list-style: none;
	margin: 0;
	max-height: 200px;
	overflow-y: auto;
	padding: 0;
	position: absolute;
	top: 100%;
	width: calc(50% - 2px);
	z-index: 1000;
}

.email-suggestions.open-upwards {
	border-bottom: none !important;
	border-top: 1px solid #ccc !important;
	bottom: 100% !important;
	top: auto !important;
}

.suggestion-item {
	cursor: pointer;
	margin-left: 4px;
	padding: 10px;
}

.suggestion-item:hover {
	background-color: #f0f0f0;
}
