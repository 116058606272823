// Dropdown
//
// @author Erik Galloway <egalloway@claruscare.com>
//
// Dropdown
// Menu
// Item
// Toggle

// Dropdown
.dropdown {
	border: 1px solid $gray-200;
	border-radius: 0.5rem;
	padding: 0.5rem 1.2rem;
	position: relative;

	@include font-nunito();
}

// Menu
.dropdown-menu {
	background-color: $white;
	border: 1px solid $gray-200;
	box-shadow: $box-shadow-lg;
	color: $black;
	padding: 0.5rem 0;
	position: absolute;
	right: 0;
	top: calc(100% - 1px);
	width: 100%;
	z-index: $zindex-dropdown;

	@include font-roboto();

	@include media-breakpoint-down(md) {
		min-width: 9.375rem;
	}

	&.dropdown-menu-wide {
		min-width: 300px;
	}
}

// Item
.dropdown-item {
	align-items: center;
	background-color: transparent;
	color: $gray-800;
	display: flex;
	padding: 0.5rem 1rem;

	@include hover-focus {
		background-color: $gray-200;
		color: $black;
		outline: none;
		text-decoration: none;
	}
}

// Toggle

.with-caret,
.dropdown-toggle {
	@include caret();
}
