// Form - checkbox
//
// @author Erik Galloway <egalloway@claruscare.com>
//
// Wrapper
// Input
// Label

// Wrapper

.form-check {
	display: block;
	padding-left: 1.25rem;
	position: relative;
}

// Input

.form-check-input {
	margin-left: -1.25rem;
	margin-top: 0.4375rem;
	position: absolute;
}

// Label

.form-check-label {
	color: $gray-700;
	margin-bottom: 0;

	@include letter-spacing(0.7, 0.875rem);
	@include font-roboto($font-size: 0.875rem);
}
