// Shared - animations
//
// @author Erik Galloway <egallowya@claruscare.com>
//
// Fade
// Vue animation

// Fade
.fade-enter-active,
.fade-leave-active {
	transition: opacity 200ms;
}

.fade-enter,
.fade-leave-to {
	opacity: 0;
}

.animated {
	animation-duration: 1s;
	animation-fill-mode: both;
}

@keyframes slideInRight {
	from {
		transform: translate3d(100%, 0, 0);
		visibility: visible;
	}

	to {
		transform: translate3d(0, 0, 0);
	}
}

.slideInRight {
	animation-name: slideInRight;
}

@keyframes slideOutRight {
	from {
		transform: translate3d(0, 0, 0);
	}

	to {
		transform: translate3d(100%, 0, 0);
		visibility: hidden;
	}
}

.slideOutRight {
	animation-name: slideOutRight;
}

// Vue animation

@mixin make-animation($name, $enter, $leave) {
	.#{$name}-enter-active {
		animation-name: $enter;
	}

	.#{$name}-leave-active {
		animation-name: $leave;
	}
}
