// Stats
//
// @author Alejandro Sanchez <asanchez@claruscare.com>
//
// View
// Container
// Title
// Card

// View

@use "sass:math";

.stats {
	display: none;
	flex-direction: column;
	margin-top: $navbar-height;
	overflow-x: hidden;
	overflow-y: auto;
	padding: 1rem;
	width: 100%;

	@include media-breakpoint-up(md) {
		display: flex;
	}
}

// Container
.stats-container {
	display: flex;
	flex-flow: row wrap;
	margin-bottom: 2rem;
}

// Title
.stats-title {
	border-bottom: 1px solid $gray-300;
	color: $gray-900;
	margin-bottom: 0.5rem;
	padding: 1rem;

	@include letter-spacing(0.25, 2.125rem);
	@include font-roboto($font-size: 2.125rem);
}

// Card
.stats-card {
	background: $white;
	border: 1px solid $gray-400;
	border-radius: 0.25rem;
	box-shadow: $box-shadow-lg;
	cursor: pointer;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin: 0.5rem;
	min-height: 8.5rem;
	overflow: hidden;
	padding: 0.75rem;
	transition: all 0.3s ease;
	width: 100%;

	&:hover {
		background: $gray-200;
		border-color: $gray-400;
	}

	&.active {
		background: $gray-300;
		border-color: $gray-500;
		box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
		transform: translateY(1px);
	}

	@include media-breakpoint-up(md) {
		width: calc(50% - 1rem);
	}

	@include media-breakpoint-up(lg) {
		width: calc(#{percentage(math.div(1, 3))} - 1rem);
	}
}

.stats-card-title {
	color: $gray-800;

	@include letter-spacing(0.25, 1.125rem);
	@include font-nunito($font-size: 1.25rem, $font-weight: 600);
}

.stats-quantity-subtitle {
	color: $gray-600;
	text-align: right;

	@include letter-spacing(0.5, 0.875rem);
	@include font-roboto($font-size: 0.875rem, $font-weight: 500);
}

.stats-quantity {
	align-items: center;
	color: $purple;
	display: flex;
	justify-content: space-between;
	width: 100%;
}

.stats-quantity-number {
	margin-right: 0.25rem;

	@include font-nunito($font-size: 2.75rem, $font-weight: 300);
}
