// Auth Layout
//
// @author Erik Galloway <egalloway@claruscare.com>
//
// Left (page) container
// Right container

// Left (page) container
.auth-left {
	display: flex;
	flex-direction: column;
	margin: 0 auto;
	max-width: 480px;
	padding: 1rem;
	width: 100%;

	@include media-breakpoint-up(md) {
		overflow-y: auto;
		padding: 2rem;
		width: 50%;
	}

	@include media-breakpoint-up(lg) {
		width: 40%;
	}
}

// Right container
.auth-right {
	align-items: center;
	background: linear-gradient(
			45deg,
			rgba(0, 8, 80, 0.3) 0%,
			rgba(0, 3, 32, 0.3) 100%
		),
		radial-gradient(
			100% 225% at 100% 0%,
			hsl(18, 100%, 58%) 0%,
			rgba(0, 0, 0, 0.3) 100%
		),
		linear-gradient(
			225deg,
			rgba(255, 123, 0, 0.3) 0%,
			rgba(0, 0, 0, 0.3) 100%
		),
		linear-gradient(
			135deg,
			rgba(205, 255, 235, 0.3) 10%,
			rgba(205, 255, 235, 0.3) 35%,
			rgba(0, 159, 157, 0.3) 35%,
			rgba(0, 158, 155, 0.3) 60%,
			rgba(7, 69, 111, 0.3) 60%,
			rgba(7, 69, 111, 0.3) 67%,
			rgba(15, 10, 60, 0.3) 67%,
			rgba(15, 10, 60, 0.3) 100%
		);
	background-blend-mode: screen, overlay, hard-light, normal;
	background-size: contain;
	display: none;
	justify-content: center;
	padding: 2rem;

	@include media-breakpoint-up(md) {
		display: flex;
		width: 50%;
	}

	@include media-breakpoint-up(lg) {
		width: 60%;
	}

	> .auth-right-inner {
		background: url('~@/assets/img/undraw-2fa.svg') no-repeat center center;
		background-size: contain;
		height: 100%;
		max-width: 50rem;
		opacity: 0.85;
		width: 100%;
	}
}
