// Call Filter
//
// @author Erik Galloway <egalloway@claruscare.com>
//
// Component
// Toggle
// Dropdown
// Header
// Option item
// Toggle items
// Submit

// Component
.call-filters {
	display: flex;
	flex-direction: column;
	margin: 0.75rem 0;
	padding: 0 0.5rem;

	@include media-breakpoint-up(md) {
		flex-direction: row;
		justify-content: space-between;
	}
}

.call-filter {
	margin-top: 0.5rem;
	position: relative;
	width: 100%;

	@include media-breakpoint-up(md) {
		margin-top: 0;
		width: 50%;

		&:first-child {
			margin-right: 0.25rem;
		}

		&:last-child {
			margin-left: 0.25rem;
		}
	}
}

// Toggle
.call-filter-toggle {
	box-shadow: $box-shadow;
	padding: 0 0.5rem;
	white-space: nowrap;
	width: 100%;

	@include font-roboto($font-size: 0.875rem, $font-weight: 500);
	@include letter-spacing(1.25, 0.875rem);

	> svg {
		margin-left: 1rem;
	}
}

.call-filter-dropdown {
	background-color: $white;
	border: 1px solid rgba(0, 0, 0, 0.15);
	box-shadow: $box-shadow-xl;
	display: none;
	margin: 0.125rem 0 0;
	max-height: 18em;
	max-width: 100%;
	min-height: 10em;
	position: absolute;
	right: 0;
	width: 100%;
	z-index: $zindex-dropdown;

	&.show {
		display: flex;
	}

	@include media-breakpoint-up(md) {
		min-width: calc(#{$inbox-width-md} - 3rem);
	}

	@include media-breakpoint-up(lg) {
		min-width: calc(#{$inbox-width-lg} - 3rem);
	}

	@include media-breakpoint-up(xl) {
		min-width: calc(#{$inbox-width-xl} - 3rem);
	}
}

.call-filter:first-child .call-filter-dropdown {
	left: 0;
	margin-right: 1rem;
	right: inherit;
}

// Dropdown
.call-filter-dropdown-list {
	background-clip: padding-box;
	background-color: $white;
	color: $gray-800;
	display: flex;
	flex-direction: column;
	font-size: 1rem;
	list-style: none;
	margin-bottom: 3.9375rem;
	overflow-y: auto;
	padding-bottom: 0.5rem;
	text-align: left;
	width: 100%;
}

// Header

.call-filter-header {
	color: #6c757d;
	display: block;
	font-size: 0.875rem;
	margin-bottom: 0;
	padding: 0.5rem 1.5rem;
	white-space: nowrap;
}

// Option item

.call-filter-item {
	align-items: center;
	background-color: transparent;
	border: 0;
	clear: both;
	color: $gray-800;
	cursor: pointer;
	display: flex;
	font-weight: 400;
	justify-content: flex-start;
	min-height: 1.75rem;
	padding: 0.25rem;
	text-align: inherit;
	white-space: nowrap;
	width: 100%;

	&[role='option'] {
		padding-bottom: 0;
		padding-top: 0;
	}

	&[role='option']:hover {
		background-color: $gray-200;
		color: $gray-800;
		text-decoration: none;
	}
}

.call-filter-item-badge {
	align-items: center;
	background: rgba($purple, 0.12);
	border-radius: 16px;
	color: $purple;
	display: inline-flex;
	font-size: 0.75rem;
	font-weight: 500;
	justify-content: center;
	min-width: 2rem;
	padding: 0 0.5rem;
}

// Toggle item

.call-filter-toggle-all {
	align-items: center;
	display: flex;
	justify-content: flex-end;
	min-height: 2.5rem;
	padding: 0.25rem 0.5rem;
	width: 100%;

	> .btn {
		min-width: 7.75rem;
	}
}

.call-filter-toggle-btn {
	border: 0;
	font-size: 0.875rem;
	margin-left: auto;
	min-width: 7.75rem;
	padding: 0.5rem;
	text-transform: uppercase;

	@include font-roboto($font-size: 0.875rem, $font-weight: 500);
	@include letter-spacing(1.25, 0.875rem);
}

.call-filter-option-checkbox {
	margin-left: 1.5rem;
	margin-right: 0.5rem;
}

.call-filter-option-label {
	cursor: pointer;
	margin-bottom: 0;
	padding: 0.25rem 0;
	padding-right: 1.5rem;
	width: 100%;
}

// Submit

.call-filter-submit {
	align-items: center;
	background-color: $gray-100;
	border-top: 1px solid $gray-200;
	bottom: 0;
	display: flex;
	height: 3.9375rem;
	left: 0;
	padding: 0.5rem 1.75rem;
	position: absolute;
	right: 0;
}
