// Providers
//
// @author Alejandro Sanchez <asanchez@claruscare.com>
//
// View
// Name
// Container
// Sections
// Card
// Card title

// View

.provider-show {
	display: flex;
	flex-direction: column;
	margin-top: $navbar-height;
	overflow-x: hidden;
	overflow-y: auto;
	padding: 1rem;
	width: 100%;
}

// Name

.provider-name-title {
	margin-bottom: 1rem;
	padding: 1rem;

	@include font-roboto($font-size: 1.5rem);
}

// Container

.provider-container {
	display: flex;
	flex-direction: column;
	width: 100%;

	@include media-breakpoint-up(lg) {
		flex-flow: row nowrap;
	}
}

// Sections

.provider-left {
	width: 100%;

	@include media-breakpoint-up(lg) {
		margin-right: 0.5rem;
		width: 60%;
	}
}

.provider-right {
	width: 100%;

	@include media-breakpoint-up(lg) {
		margin-left: 0.5rem;
		width: 40%;
	}
}

// Card

.provider-card {
	background: $white;
	border: 1px solid $gray-400;
	border-radius: 0.25rem;
	margin-bottom: 1rem;
	overflow: hidden;
	padding: 1rem;
}

// Card title

.provider-form-title {
	margin-bottom: 1rem;
	width: 100%;

	@include font-nunito($font-size: 1.25rem, $font-weight: 600);
}
