// Chat
//
// @author Alejandro Sanchez <asanchez@claruscare.com>
//
// View

// View

.chat-show {
	display: flex;
	flex-direction: column;
	margin-top: $navbar-height;
	overflow-y: auto;
	padding: 0.75rem;
	width: 100%;

	svg {
		display: inline-block;
		fill: currentColor;
		font-size: 20px;
		height: 20px;
		width: 20px !important;
	}
}

.chat-header {
	align-items: center;
	display: flex;
	justify-content: space-between;
	padding: 1rem;
}

.chat-title {
	@include font-roboto($font-size: 1.5rem);
}

.chat-body {
	background-color: $white;
	border-radius: 0.25rem;
	border-style: solid;
	border-width: 1px;
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	overflow-x: auto;
	padding: 1rem;
	width: 100%;
}

.chat-body > :first-child {
	margin-top: auto !important;
}

.chat-footer {
	align-items: center;
	background-color: $white;
	border-radius: 0.25rem;
	border-style: solid;
	border-width: 1px;
	display: flex;
	flex-direction: row;
	min-height: 6rem;
	padding: 1rem;
}

// Message
.message {
	margin-bottom: 1rem;
}

.message-header {
	display: flex;
	font-weight: bold;
	justify-content: flex-end;
}

.message-body {
	margin: 0.5rem 0;

	@include media-breakpoint-up(sm) {
		min-width: 20rem;
	}
}

.message-footer {
	@include font-roboto($font-size: 0.875rem, $font-weight: 500);
}

.message-text-container {
	display: flex;
	width: 100%;
}

.message-text {
	border-radius: 0.25rem;
	border-style: solid;
	border-width: 1px;
	padding: 0.5rem;
	resize: none;
	width: 100%;
}
