// Fonts
//
// @author Erik Galloway <egalloway@claruscare.com>
//
// Nunito
// Roboto

// Nunito

@font-face {
	font-display: swap;
	font-family: 'Nunito';
	font-style: normal;
	font-weight: 100;
	src: url('assets/fonts/Nunito/Nunito-ExtraLight.ttf') format('truetype');
}

@font-face {
	font-display: swap;
	font-family: 'Nunito';
	font-style: italic;
	font-weight: 100;
	src: url('assets/fonts/Nunito/Nunito-ExtraLightItalic.ttf')
		format('truetype');
}

@font-face {
	font-display: swap;
	font-family: 'Nunito';
	font-style: normal;
	font-weight: 200;
	src: url('assets/fonts/Nunito/Nunito-Light.ttf') format('truetype');
}

@font-face {
	font-display: swap;
	font-family: 'Nunito';
	font-style: italic;
	font-weight: 200;
	src: url('assets/fonts/Nunito/Nunito-LightItalic.ttf') format('truetype');
}

@font-face {
	font-display: swap;
	font-family: 'Nunito';
	font-style: normal;
	font-weight: 400;
	src: url('assets/fonts/Nunito/Nunito-Regular.ttf') format('truetype');
}

@font-face {
	font-display: swap;
	font-family: 'Nunito';
	font-style: italic;
	font-weight: 400;
	src: url('assets/fonts/Nunito/Nunito-Italic.ttf') format('truetype');
}

@font-face {
	font-display: swap;
	font-family: 'Nunito';
	font-style: normal;
	font-weight: 600;
	src: url('assets/fonts/Nunito/Nunito-SemiBold.ttf') format('truetype');
}

@font-face {
	font-display: swap;
	font-family: 'Nunito';
	font-style: italic;
	font-weight: 600;
	src: url('assets/fonts/Nunito/Nunito-SemiBoldItalic.ttf') format('truetype');
}

@font-face {
	font-display: swap;
	font-family: 'Nunito';
	font-style: normal;
	font-weight: 700;
	src: url('assets/fonts/Nunito/Nunito-Bold.ttf') format('truetype');
}

@font-face {
	font-display: swap;
	font-family: 'Nunito';
	font-style: italic;
	font-weight: 700;
	src: url('assets/fonts/Nunito/Nunito-BoldItalic.ttf') format('truetype');
}

@font-face {
	font-display: swap;
	font-family: 'Nunito';
	font-style: normal;
	font-weight: 800;
	src: url('assets/fonts/Nunito/Nunito-ExtraBold.ttf') format('truetype');
}

@font-face {
	font-display: swap;
	font-family: 'Nunito';
	font-style: italic;
	font-weight: 800;
	src: url('assets/fonts/Nunito/Nunito-ExtraBoldItalic.ttf')
		format('truetype');
}

@font-face {
	font-display: swap;
	font-family: 'Nunito';
	font-style: normal;
	font-weight: 900;
	src: url('assets/fonts/Nunito/Nunito-Black.ttf') format('truetype');
}

@font-face {
	font-display: swap;
	font-family: 'Nunito';
	font-style: normal;
	font-weight: 900;
	src: url('assets/fonts/Nunito/Nunito-BlackItalic.ttf') format('truetype');
}

// Roboto

@font-face {
	font-display: swap;
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 100;
	src: url('assets/fonts/Roboto/Roboto-Thin.eot') format('embedded-opentype');
	src: url('assets/fonts/Roboto/Roboto-Thin.ttf') format('truetype');
	src: url('assets/fonts/Roboto/Roboto-Thin.woff') format('woff');
	src: url('assets/fonts/Roboto/Roboto-Thin.woff2') format('woff2');
}

@font-face {
	font-display: swap;
	font-family: 'Roboto';
	font-style: italic;
	font-weight: 100;
	src: url('assets/fonts/Roboto/Roboto-ThinItalic.eot')
		format('embedded-opentype');
	src: url('assets/fonts/Roboto/Roboto-ThinItalic.ttf') format('truetype');
	src: url('assets/fonts/Roboto/Roboto-ThinItalic.woff') format('woff');
	src: url('assets/fonts/Roboto/Roboto-ThinItalic.woff2') format('woff2');
}

@font-face {
	font-display: swap;
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 300;
	src: url('assets/fonts/Roboto/Roboto-Light.eot') format('embedded-opentype');
	src: url('assets/fonts/Roboto/Roboto-Light.ttf') format('truetype');
	src: url('assets/fonts/Roboto/Roboto-Light.woff') format('woff');
	src: url('assets/fonts/Roboto/Roboto-Light.woff2') format('woff2');
}

@font-face {
	font-display: swap;
	font-family: 'Roboto';
	font-style: italic;
	font-weight: 300;
	src: url('assets/fonts/Roboto/Roboto-LightItalic.eot')
		format('embedded-opentype');
	src: url('assets/fonts/Roboto/Roboto-LightItalic.ttf') format('truetype');
	src: url('assets/fonts/Roboto/Roboto-LightItalic.woff') format('woff');
	src: url('assets/fonts/Roboto/Roboto-LightItalic.woff2') format('woff2');
}

@font-face {
	font-display: swap;
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	src: url('assets/fonts/Roboto/Roboto-Regular.eot')
		format('embedded-opentype');
	src: url('assets/fonts/Roboto/Roboto-Regular.ttf') format('truetype');
	src: url('assets/fonts/Roboto/Roboto-Regular.woff') format('woff');
	src: url('assets/fonts/Roboto/Roboto-Regular.woff2') format('woff2');
}

@font-face {
	font-display: swap;
	font-family: 'Roboto';
	font-style: italic;
	font-weight: 400;
	src: url('assets/fonts/Roboto/Roboto-RegularItalic.eot')
		format('embedded-opentype');
	src: url('assets/fonts/Roboto/Roboto-RegularItalic.ttf') format('truetype');
	src: url('assets/fonts/Roboto/Roboto-RegularItalic.woff') format('woff');
	src: url('assets/fonts/Roboto/Roboto-RegularItalic.woff2') format('woff2');
}

@font-face {
	font-display: swap;
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 500;
	src: url('assets/fonts/Roboto/Roboto-Medium.eot')
		format('embedded-opentype');
	src: url('assets/fonts/Roboto/Roboto-Medium.ttf') format('truetype');
	src: url('assets/fonts/Roboto/Roboto-Medium.woff') format('woff');
	src: url('assets/fonts/Roboto/Roboto-Medium.woff2') format('woff2');
}

@font-face {
	font-display: swap;
	font-family: 'Roboto';
	font-style: italic;
	font-weight: 500;
	src: url('assets/fonts/Roboto/Roboto-MediumItalic.eot')
		format('embedded-opentype');
	src: url('assets/fonts/Roboto/Roboto-MediumItalic.ttf') format('truetype');
	src: url('assets/fonts/Roboto/Roboto-MediumItalic.woff') format('woff');
	src: url('assets/fonts/Roboto/Roboto-MediumItalic.woff2') format('woff2');
}

@font-face {
	font-display: swap;
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 700;
	src: url('assets/fonts/Roboto/Roboto-Bold.eot') format('embedded-opentype');
	src: url('assets/fonts/Roboto/Roboto-Bold.ttf') format('truetype');
	src: url('assets/fonts/Roboto/Roboto-Bold.woff') format('woff');
	src: url('assets/fonts/Roboto/Roboto-Bold.woff2') format('woff2');
}

@font-face {
	font-display: swap;
	font-family: 'Roboto';
	font-style: italic;
	font-weight: 700;
	src: url('assets/fonts/Roboto/Roboto-BoldItalic.eot')
		format('embedded-opentype');
	src: url('assets/fonts/Roboto/Roboto-BoldItalic.ttf') format('truetype');
	src: url('assets/fonts/Roboto/Roboto-BoldItalic.woff') format('woff');
	src: url('assets/fonts/Roboto/Roboto-BoldItalic.woff2') format('woff2');
}

@font-face {
	font-display: swap;
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 900;
	src: url('assets/fonts/Roboto/Roboto-Black.eot') format('embedded-opentype');
	src: url('assets/fonts/Roboto/Roboto-Black.ttf') format('truetype');
	src: url('assets/fonts/Roboto/Roboto-Black.woff') format('woff');
	src: url('assets/fonts/Roboto/Roboto-Black.woff2') format('woff2');
}

@font-face {
	font-display: swap;
	font-family: 'Roboto';
	font-style: italic;
	font-weight: 900;
	src: url('assets/fonts/Roboto/Roboto-BlackItalic.eot')
		format('embedded-opentype');
	src: url('assets/fonts/Roboto/Roboto-BlackItalic.ttf') format('truetype');
	src: url('assets/fonts/Roboto/Roboto-BlackItalic.woff') format('woff');
	src: url('assets/fonts/Roboto/Roboto-BlackItalic.woff2') format('woff2');
}
