// Tooltips
//
// @author Erik Galloway <egalloway@claruscare.com>
//
// Tooltip
// Right position
// Left position
// Top position
// Top left offset
// Top right offset

// Tooltip

.tooltip,
[data-tooltip] {
	position: relative;

	&:hover {
		z-index: $zindex-tooltip;
	}

	&::before {
		align-items: center;
		background: $tooltip-background;
		border-radius: $tooltip-border-radius;
		color: $white;
		display: inline-flex;
		flex-grow: 1;
		font-size: $tooltip-font-size !important;
		justify-content: center;
		left: 50%;
		margin-top: 5px;
		max-height: 26px;
		min-width: $tooltip-min-width;
		opacity: 0;
		padding: $tooltip-padding-x $tooltip-padding-y;
		pointer-events: none;
		position: absolute;
		text-transform: none;
		top: 100%;
		transform: translate3d(-50%, -10px, 0) scale(0.8);
		transition: $tooltip-transition;
		white-space: nowrap;
		z-index: $zindex-tooltip;

		@include letter-spacing(0.4, 0.75rem);
		@include font-roboto($font-size: 0.75rem, $font-weight: 500);
	}

	&:hover::before {
		opacity: 1;
		transform: translate3d(-50%, 0, 0) scale(1);
	}
}

[data-tooltip]::before {
	content: $tooltip-content;
}

.tooltip::before {
	content: attr(aria-label);
}

// Right position

.tooltip-right,
[data-position='right'] {
	&::before {
		left: 100%;
		margin-left: 5px;
		margin-top: 0;
		top: 50%;
		transform: translate3d(-10px, -50%, 0) scale(0.8);
	}

	&:hover::before {
		transform: translate3d(0, -50%, 0) scale(1);
	}
}

// Left position

.tooltip-left,
[data-position='left'] {
	&::before {
		left: auto;
		margin-right: 5px;
		margin-top: 0;
		right: 100%;
		top: 50%;
		transform: translate3d(-10px, -50%, 0) scale(0.8);
	}

	&:hover::before {
		transform: translate3d(0, -50%, 0) scale(1);
	}
}

// Top position

.tooltip-top,
[data-position='top'] {
	&::before {
		left: 50%;
		margin-bottom: 5px;
		margin-top: 0;
		top: -30px;
		transform: translate3d(-50%, 10px, 0) scale(0.8);
	}

	&:hover::before {
		transform: translate3d(-50%, 0, 0) scale(1);
	}
}

// Top left offset

.tooltip-top,
[data-position='top-left'] {
	&::before {
		left: auto;
		margin-bottom: 5px;
		margin-top: 0;
		right: 0;
		top: -30px;
		transform: translate3d(15%, 10px, 0) scale(0.8);
	}

	&:hover::before {
		transform: translate3d(15%, 0, 0) scale(1);
	}
}

// Top right offset

.tooltip-top-right,
[data-position='top-right'] {
	&::before {
		left: 25%;
		margin-bottom: 5px;
		margin-top: 0;
		top: -30px;
		transform: translate3d(-25%, 10px, 0) scale(0.8);
	}

	&:hover::before {
		transform: translate3d(-25%, 0, 0) scale(1);
	}
}
