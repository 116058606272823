// Editable Content
//
// @author Erik Galloway <egalloway@claruscare.com>
//
// Content editable

// Content editable

[contenteditable] {
	border: 1px dashed transparent;
}

[contenteditable='true'] {
	border: 1px dashed $gray-400;
	outline: 0;
}

.call-details-edit {
	border: 1px solid $gray-400;
	border-radius: 4px;
	height: 32px;

	div {
		border: none;
	}
}

.call-message-edit {
	div {
		border: none;
	}
}
