// Variables
$card-label: #64738b;
$card-label-size: 16px;
$card-value-color: #4a5568;
$card-value-size: 18px;
$warning-error-color: #da4423;

.profile-name-title {
	color: #1a202c;
	font-size: 24px;
	font-weight: 400;
}

.tab-container {
	margin-bottom: 50px;
	padding: 0 20px;
	width: 100%;

	@include font-roboto();
	.tabs {
		background: #fff;
		border: 1px solid #cbd5e0;
		border-radius: 4px;
		display: flex;
		margin-bottom: 20px;
		.tab {
			border: none;
			border-bottom: 2px solid transparent;
			border-bottom: 4px solid transparent;
			border-right: 1px solid #cbd5e0;
			color: #1a202c;
			cursor: pointer;
			font-size: 22px;
			height: 53px;
			outline: none;
			transition: background-color 0.3s ease;
			width: 33.33%;
			width: 33.33%;
			&:last-child {
				border-right: 1px solid transparent;
			}
			&.active {
				background-color: #fff;
				border-bottom: 4px solid #6200ee;
				color: #1a202c;
			}
			&:hover {
				background-color: #f1f1f1;
			}
			&.disabled {
				background-color: #edebeb;
				color: #cfcccc;
				cursor: not-allowed;
			}
		}
	}
	.tab-content {
		.card-title {
			justify-content: left;
			&_left-setting-page {
				align-items: center;
				border-right: 1px solid #cbd5e0;
				display: flex;
				height: 52px;
				width: 50%;
			}
			&_right-setting-page {
				align-items: center;
				display: flex;
				height: 52px;
				padding-left: 20px;
				width: 50%;
			}
		}
		.card-body {
			display: flex;
			flex-wrap: wrap;
			margin-top: 0;
			min-height: 60px;
			width: 100%;
			&-container {
				padding: 15px 20px;
				width: 100%;
				&.note-type-container {
					padding: 0;
				}
				.icon-container {
					display: flex;
					justify-content: flex-end;
				}
			}
			&_left-setting-page {
				border-right: 1px solid #cbd5e0;
				padding: 10px 0;
				width: 50%;
			}
			&_right-setting-page {
				padding: 10px 0;
				width: 50%;
			}
			.form-item__inputField-input {
				width: 100%;
			}
			.coordination-note-container {
				display: flex;
				gap: 20px;
				width: 100%;
				&_left {
					padding: 15px 0;
					padding-left: 20px;
					width: 50%;
					.coordination-note-check-box-container {
						max-height: 300px;
						overflow-y: auto;
					}
					.note-type-item {
						align-items: flex-start;
						display: flex;
						.custom-checkbox {
							display: flex;
							margin-right: 10px;
							input[type='checkbox'] {
								display: none;
							}
							label {
								cursor: pointer;
								display: inline-block;
								font-size: 16px;
								padding-left: 30px;
								position: relative;
								user-select: none;
								&::before {
									border: 2px solid #cbd5e0;
									border-radius: 0;
									content: '';
									height: 30px;
									left: 0;
									position: absolute;
									top: 0;
									transition: all 0.3s ease;
									width: 30px;
								}
								/* Check icon (initially hidden) */
								&::after {
									background: url('data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjE0IiB3aWR0aD0iMTgiIGZpbGw9Im5vbmUiIHZpZXdCb3g9IjAgMCAxOCAxNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgIDxwYXRoIGQ9Ik0xNi41IDEuNUw2LjE4NzUgMTIuNUwxLjUgNy41IiBzdHJva2U9IiM0QTU1NjgiIHN0cm9rZUxpbmVjYXA9InJvdW5kIiBzdHJva2VMaW5lam9pbj0icm91bmQiIHN0cm9rZVdpZHRoPSIyIi8+Cjwvc3ZnPgo=')
										no-repeat center;
									content: '';
									display: none;
									height: 14px;
									left: 6px;
									position: absolute;
									top: 6px;
									width: 18px;
								}
							}
							input[type='checkbox']:checked + label::after {
								display: block;
							}
							input[type='checkbox']:checked + label::before {
								background-color: #cbd5e0;
								border-color: #cbd5e0;
							}
						}
						.note-label {
							background: #cbd5e0;
							color: #4a5568;
							font-size: 18px;
							font-weight: bold;
							padding: 1px 10px;
							word-break: break-word;
						}
					}
				}
				&_right {
					border-left: 1px solid #cbd5e0;
					width: 50%;
					.note-item-name {
						border-bottom: 1px solid #cbd5e0;
						color: #4a5568;
						font-size: 20px;
						font-weight: 500;
						min-height: 50px;
						padding: 10px 20px;
					}
					.note-item-details {
						padding: 20px;
					}
					.field-label {
						color: #4a5568;
						font-size: 18px;
						font-weight: 500;
						line-height: initial;
						margin-bottom: 0;
						padding: 0 2px;
					}
					.field-label-disable {
						color: #8d9db5;
						font-size: 18px;
						font-weight: 500;
						padding-left: 5px;
						word-break: break-word;
					}
				}
			}

			.form-group {
				font-weight: 500;
				line-height: 1.2;
				margin-bottom: 10px;
				label {
					color: $card-label;
					display: block;
					font-size: $card-label-size;
					font-weight: 500;
					font-weight: 500;
					line-height: 1.2;
					line-height: 1.2;
					margin-bottom: 0;
				}
				.form-input {
					align-items: center;
					border: 1px solid #cbd5e0;
					border-radius: 4px;
					color: $card-value-color;
					display: flex;
					display: flex;
					font-size: $card-value-size;
					font-weight: 500;
					line-height: 1.2;
					margin-top: 5px;
					min-height: 32px;
					padding: 0 10px;
					position: relative;
					word-break: break-word;
				}
				.nonEditable-text {
					align-items: center;
					background-color: #cbd5e0;
					border: 1px solid #cbd5e0;
					border-radius: 4px;
					color: $card-value-color;
					display: flex;
					font-size: $card-value-size;
					font-weight: 500;
					height: auto !important;
					justify-content: flex-start;
					line-height: 1.2;
					margin-top: 5px;
					min-height: 32px !important;
					padding: 10px !important;
					position: relative;
					word-break: break-word;
				}
				.input-tag-container {
					margin-top: 5px;
				}
			}
		}
		.card-footer {
			&_left {
				.warning {
					color: $warning-error-color;
					font-size: 18px;
					font-weight: 500;
				}
			}
			.action-button {
				align-items: center;
				border: 1px solid #4a5568;
				border-radius: 4px;
				color: #4a5568;
				display: flex;
				font-size: 16px;
				font-weight: 500;
				height: 31px;
				justify-content: center;
				width: 60px;
				&:hover {
					background: #f1f1f1;
				}
				&:disabled {
					border-color: #cbd5e0;
					color: #cbd5e0;
					cursor: not-allowed;
				}
			}
		}
	}
}
