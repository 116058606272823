// Dialog
//
// @author Alejandro Sanchez <asanchez@claruscare.com>
//
// Dialog
// Container
// Header
// Body

// Dialog
.dialog {
	align-items: center;
	background-color: rgba(0, 0, 0, 0.5);
	bottom: 0;
	display: flex;
	justify-content: center;
	left: 0;
	position: fixed;
	right: 0;
	top: 0;
	z-index: $zindex-dialog-modal;

	@include letter-spacing(0.7, 0.875rem);
	@include font-roboto($font-size: 0.875rem);
}

// Container
.dialog-container {
	background-color: $white;
	border: 1px solid $gray-400;
	border-radius: 0.25rem;
	display: flex;
	flex-direction: column;
	margin: 2rem 0;
	max-width: 28rem;
	min-height: 11.5rem;
	overflow: hidden;
	position: fixed;
	vertical-align: middle;
	width: 100%;
}

.dialog-header {
	align-items: center;
	display: flex;
	justify-content: space-between;
	margin-bottom: 1rem;
	padding: 1rem;
}

.dialog-body {
	display: flex;
	flex-direction: column;
	padding: 1rem;
}

.dialog-title {
	color: $gray-800;

	@include font-roboto($font-weight: 400, $font-size: 1.5rem);
	@include letter-spacing(0.15, 1.5rem);
}

.dialog-footer {
	display: flex;
	justify-content: space-between;
	padding: 1rem;
}
