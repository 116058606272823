// Shared - typography
//
// @author Erik Galloway <egalloway@claruscare.com>
//
// Calculate letter-spacing
// Letter-spacing mixin
// Chrome & Firefox font smoothing
// Font family mixin
// Nunito font mixin
// Roboto font mixin

// Calculate letter-spacing

// Element | tracking(spacing) | font-size (rem)
// h1           -1.5                6rem
// h2           -0.5                3.75rem
// h3           0                   3rem
// h4           0.25                2.125rem
// h5           0                   1.5rem
// h6           0.25                1.25rem
// subtitle     0.15                1rem
// subtitle2    0.1                 0.875rem
// body         0.5                 1rem
// body2        0.25                0.875rem
// button       1.25                0.875rem
// caption      0.4                 0.75rem
// overline     1.5                 0.75rem

@use "sass:math";

@function get-letter-spacing($tracking, $font-size) {
	@if unitless($number: $font-size) {
		@return math.div($tracking, $font-size * 16) * 1em;
	}

	$font-size-value: math.div($font-size, $font-size * 0 + 1);

	@return math.div($tracking, $font-size-value * 16) * 1em;
}

// Letter-spacing mixin

@mixin letter-spacing($tracking, $font-size: 1rem) {
	letter-spacing: get-letter-spacing($tracking, $font-size);
}

// Chrome & Firefox font smoothing

@mixin browser-font-smooth() {
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	text-rendering: optimizeLegibility;
}

// Font family mixin

@mixin font-family($font-family, $font-weight: false, $font-size: false) {
	font-family: $font-family;

	@if $font-size {
		font-size: $font-size;
	}

	@if $font-weight {
		font-weight: $font-weight;
	}

	@include browser-font-smooth();
}

// Nunito font mixin

@mixin font-nunito($font-weight: false, $font-size: false) {
	@include font-family($font-family-nunito, $font-weight, $font-size);
}

// Roboto font mixin

@mixin font-roboto($font-weight: false, $font-size: false) {
	@include font-family($font-family-roboto, $font-weight, $font-size);
}
