// Context Menu
//
// @author Alejandro Sanchez <asanchez@claruscare.com>
//
// Context Menu
// Item

// Context Menu
.context-menu {
	background: $gray-900;
	border-radius: 0.25rem;
	position: fixed;
	transform: scale(0);
	transform-origin: top left;
	width: 10rem;
	z-index: $zindex-context-menu;
}

.context-menu.active {
	transform: scale(1);
	transition: transform 300ms ease-in-out;
}

// Item
.context-menu .item {
	align-items: center;
	color: $white;
	display: flex;
	padding: 0.5rem 0.625rem;
	width: 100%;

	@include letter-spacing(1.25, 0.875rem);
	@include font-roboto($font-size: 0.875rem, $font-weight: 500);
}

.context-menu .item:hover {
	background: $gray-700;
}
