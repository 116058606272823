.wmaReply-section {
	.panel-bg {
		height: 100%;
		left: calc(100% - 640px);
		position: fixed;
		top: 0;
		width: 100%;
	}
	.panel-container {
		width: 640px !important;
		.panel {
			border-left: 3px solid #805ad5;
		}
		.panel-header {
			background: transparent !important;
			padding: 20px;
			.panel-title {
				color: #2d3748;
				margin: 0;
			}
			button {
				color: #2d3748 !important;
				&:focus {
					background: transparent;
				}
			}
		}
		.panel-body {
			padding-top: 43px;
		}
	}
	.wmaReply__title-icon {
		align-items: center;
		display: flex;
		font-size: 14px;
		font-weight: 500;
		justify-content: center;
	}
	.form-group {
		font-weight: 500;
		line-height: 1.2;
		margin-bottom: 10px;
		text-align: left;
		label {
			color: $card-label;
			display: block;
			font-size: $card-label-size;
			font-weight: 500;
			font-weight: 500;
			line-height: 1.2;
			line-height: 1.2;
			margin-bottom: 0;
		}
		.textarea {
			align-items: center;
			border: 1px solid #cbd5e0;
			color: $card-value-color;
			display: flex;
			font-size: $card-value-size;
			font-weight: 500;
			line-height: 1.4;
			margin-top: 5px;
			min-height: 140px;
			padding: 10px;
			position: relative;
			resize: none;
			word-break: break-word;
			&:focus,
			&:hover {
				border-color: #cbd5e0;
				outline: none;
			}
		}
		.form-input {
			align-items: center;
			border: 1px solid #cbd5e0;
			border-radius: 4px;
			color: $card-value-color;
			display: flex;
			display: flex;
			font-size: $card-value-size;
			font-weight: 500;
			line-height: 1.2;
			margin-top: 5px;
			min-height: 32px;
			padding: 0 10px;
			position: relative;
			word-break: break-word;
		}
		.nonEditable-text {
			align-items: center;
			background-color: #cbd5e0;
			border: 1px solid #cbd5e0;
			border-radius: 4px;
			color: $card-value-color;
			display: flex;
			font-size: $card-value-size;
			font-weight: 500;
			height: auto !important;
			justify-content: flex-start;
			line-height: 1.2;
			margin-top: 5px;
			min-height: 32px !important;
			padding: 10px !important;
			position: relative;
			word-break: break-word;
		}
		.input-tag-container {
			margin-top: 5px;
		}
		.dropdown {
			margin-bottom: 0;
		}
		&.form-button.reply-section {
			align-items: center;
			display: flex;
			flex-direction: row;
			justify-content: center;
			button {
				margin-right: 20px;
				&:last-child {
					margin-right: 0;
				}
			}
		}
		.dropdown-toggle {
			background: transparent;
			border: none;
			padding: 0;
		}
		.dropdown-content {
			left: 0;
			margin-top: 10px;
			label {
				color: #4a5568;
			}
		}

		.action-button {
			align-items: center;
			border: 1px solid #4a5568;
			border-radius: 4px;
			color: #4a5568;
			display: flex;
			font-size: 18px;
			font-weight: 500;
			justify-content: center;
			margin-top: 10px;
			padding: 10px 20px;
			width: auto;
			&:hover {
				background: #f1f1f1;
			}
			&:disabled {
				border-color: #cbd5e0;
				color: #cbd5e0;
				cursor: not-allowed;
			}
		}
	}
}
