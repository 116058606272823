// Tab content
//
// @author Erik Galloway <egalloway@claruscare.com>
//
// Tab content
// Tab pane
// Tab bar
// Tab scroller
// Tab bar item

// Tab content
.tab-content {
	height: calc(100% - #{$navbar-height});
}

// Tab pane
.tab-pane {
	height: 100%;
}

// Tab bar
.tab-bar {
	width: 100%;
}

// Tab scroller
.tab-scroller {
	overflow-y: hidden;
}

.tab-scroller-scroll-area {
	display: flex;
	-webkit-overflow-scrolling: touch;
	overflow-x: scroll;

	&::-webkit-scrollbar {
		display: none;
	}
}

.tab-scroller-scroll-content {
	display: flex;
	flex-grow: 1;
	position: relative;
	transform: none;
}

// Tab bar item
.tab-bar-item {
	align-items: center;
	appearance: none;
	cursor: pointer;
	display: flex;
	flex-grow: 1;
	height: $tab-bar-item-height;
	justify-content: center;
	line-height: $tab-bar-item-line-height;
	padding: $tab-bar-item-padding-y $tab-bar-item-padding-x;
	position: relative;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
	white-space: nowrap;

	@include browser-font-smooth();
	@include font-roboto($tab-bar-item-font-weight, $tab-bar-item-font-size);
	@include letter-spacing(
		$tab-bar-item-text-tracking,
		$tab-bar-item-font-size
	);

	@include hover-focus {
		outline: 0;
	}
}

.tab-bar-item-content {
	align-items: center;
	display: flex;
	justify-content: center;
	pointer-events: none;
	position: relative;

	svg {
		transition: $tab-bar-item-content-transition;

		.tab-bar-item.is-active & {
			color: $tab-bar-item-color-active;
			transition-delay: $tab-bar-item-content-transition-delay-active;
		}
	}
}

.tab-bar-item-text {
	line-height: 1;
	transition: $tab-bar-item-content-transition;

	.tab-bar-item-icon + & {
		padding-left: $tab-bar-item-icon-text-spacer;
		padding-right: 0;
	}

	.tab-bar-item.is-active & {
		color: $tab-bar-item-color-active;
		transition-delay: $tab-bar-item-content-transition-delay-active;
	}
}

.tab-bar-item.is-active .tab-bar-item-icon {
	color: $tab-bar-item-color-active;
	transition-delay: $tab-bar-item-content-transition-delay-active;
}

.tab-bar-item-indicator {
	display: flex;
	height: 100%;
	justify-content: center;
	left: 0;
	pointer-events: none;
	position: absolute;
	top: 0;
	width: 100%;
}

.tab-indicator-underline {
	align-self: flex-end;
	border-top: $tab-bar-item-underline-border;
	opacity: 0;
	transform-origin: left;
	transition: $tab-bar-item-underline-transition;
	width: 100%;

	.tab-bar-item.is-active & {
		opacity: 1;
	}
}

.tab-bar-item-bg {
	height: 100%;
	left: 0;
	overflow: hidden;
	position: absolute;
	top: 0;
	width: 100%;

	&::before,
	&::after {
		background-color: $tab-bar-item-background-active;
		border-radius: 50%;
		content: '';
		opacity: 0;
		pointer-events: none;
		position: absolute;
	}

	&::after {
		height: 100%;
		left: 0;
		top: 0;
		transform: scale(0);
		transform-origin: center center;
		width: 100%;
	}

	&::before {
		height: 200%;
		left: -50%;
		top: -50%;
		transform: scale(1);
		transition: opacity 15ms linear, background-color 15ms linear;
		width: 200%;
		z-index: 1;
	}

	&:hover::before {
		opacity: $tab-bar-item-background-opacity-hover;
	}

	.is-focused &::before {
		opacity: $tab-bar-item-background-opacity-focus;
		transition-duration: 75ms;
	}
}
