.inboundReport-section {
	.panel-bg {
		height: 100%;
		left: calc(100% - 640px);
		position: fixed;
		top: 0;
		width: 100%;
	}
	.inbound-report__title-icon {
		align-items: center;
		display: flex;
		font-size: 14px;
		font-weight: 500;
		justify-content: center;
	}
	.form-group {
		font-weight: 500;
		line-height: 1.2;
		margin-bottom: 10px;
		text-align: left;
		label {
			color: $card-label;
			display: block;
			font-size: $card-label-size;
			font-weight: 500;
			font-weight: 500;
			line-height: 1.2;
			line-height: 1.2;
			margin-bottom: 0;
		}
		.form-input {
			align-items: center;
			border: 1px solid #cbd5e0;
			border-radius: 4px;
			color: $card-value-color;
			display: flex;
			display: flex;
			font-size: $card-value-size;
			font-weight: 500;
			line-height: 1.2;
			margin-top: 5px;
			min-height: 32px;
			padding: 0 10px;
			position: relative;
			word-break: break-word;
		}
		.nonEditable-text {
			align-items: center;
			background-color: #cbd5e0;
			border: 1px solid #cbd5e0;
			border-radius: 4px;
			color: $card-value-color;
			display: flex;
			font-size: $card-value-size;
			font-weight: 500;
			height: auto !important;
			justify-content: flex-start;
			line-height: 1.2;
			margin-top: 5px;
			min-height: 32px !important;
			padding: 10px !important;
			position: relative;
			word-break: break-word;
		}
		.input-tag-container {
			margin-top: 5px;
		}
		.dropdown {
			margin-bottom: 0;
		}
		&.form-button {
			align-items: center;
			display: flex;
			justify-content: center;
			button {
			}
		}
		.dropdown-toggle {
			background: transparent;
			border: none;
			padding: 0;
		}
		.dropdown-content {
			left: 0;
			margin-top: 10px;
			label {
				color: #4a5568;
			}
		}
	}
	.inboundReport-list {
		margin-bottom: 20px;
		padding: 0 20px;
		.inboundReport-list_item {
			background: #eee;
			display: flex;
			margin-bottom: 10px;
			min-height: 73px;
			padding: 10px 20px;
			.inboundReport-list_item-icon {
				align-items: center;
				display: flex;
				flex-wrap: wrap;
				font-size: 14px;
				font-weight: 500;
				justify-content: center;
				width: 90px;
			}
			.inboundReport-list_item-content {
				align-items: baseline;
				color: #2d3748;
				display: flex;
				flex-wrap: wrap;
				font-size: 18px;
				font-weight: 500;
				justify-content: left;
				width: calc(100% - 90px);
				> div {
					text-align: left;
					width: 100%;
				}
			}
			.inboundReport-list_item-icon-failed {
				color: #c61414;
				text-align: center;
			}
		}
	}
	.action-button {
		align-items: center;
		border: 1px solid #4a5568;
		border-radius: 4px;
		color: #4a5568;
		display: flex;
		font-size: 18px;
		font-weight: 500;
		justify-content: center;
		margin-top: 10px;
		padding: 10px 20px;
		width: auto;
		&:hover {
			background: #f1f1f1;
		}
		&:disabled {
			border-color: #cbd5e0;
			color: #cbd5e0;
			cursor: not-allowed;
		}
	}
	.run-report-Button {
		appearance: none;
		background: #fff;
		border: 1px solid $gray-300;
		border-radius: 0.25rem;
		color: $gray-800;
		cursor: pointer;
		font-weight: 500;
		letter-spacing: 0.009375em;
		margin-top: 10px;
		padding: 10px 20px;
		width: auto;

		&:focus {
			border: 1px solid $gray-400;
			box-shadow: $box-shadow;
			outline: none;
		}

		@include font-roboto;

		&::placeholder {
			color: $gray-700;
			font-weight: 500;
			text-align: center;
			text-transform: uppercase;

			@include letter-spacing(0.5, 0.875rem);
		}
	}
	.dropdown-container {
		position: relative;
		.dropdown-header {
			align-items: center;
			border: 1px solid #cbd5e0;
			border-radius: 4px;
			color: #4a5568;
			display: flex;
			font-size: 18px;
			font-weight: 500;
			justify-content: space-between;
			line-height: 1.2;
			margin-top: 5px;
			min-height: 32px;
			padding: 0 10px;
			position: relative;
			word-break: break-word;
		}
		.arrow-icon {
			border: solid black;
			border-width: 0 3px 3px 0;
			padding: 3px;
			transform: rotate(45deg);
			transition: transform 0.3s;
		}

		.dropdown-header.active .arrow-icon {
			transform: rotate(-135deg);
		}

		.dropdown-list {
			background: white;
			border: 1px solid #ccc;
			border-radius: 4px;
			box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
			position: absolute;
			width: 100%;
			z-index: 100;
			.dropdown-item {
				align-items: center;
				border-bottom: 1px solid #cbd5e0;
				display: flex;
				justify-content: left;
				min-height: 35px;
				padding: 4px 12px;
				&:hover,
				&:focus {
					background: #f9f5ff;
				}
				&:last-child {
					border-bottom: 1px solid transparent;
				}
			}
		}

		.scrollable-list {
			max-height: 425px;
			overflow-y: auto;
		}

		.checkbox-label {
			align-items: center;
			border-bottom: 1px solid #cbd5e0;
			display: flex;
			justify-content: left;
			min-height: 35px;
			padding: 4px 12px;
		}

		.select-checkbox-label {
			align-items: center;
			border-bottom: 2px solid #cbd5e0;
			display: flex;
			justify-content: left;
			min-height: 35px;
			padding: 4px 12px;
		}
		.rotate-180 {
			transform: rotate(180deg);
		}
		.transition-transform {
			transition: transform 0.3s ease-in-out;
		}
	}

	.custom-checkbox {
		align-items: center;
		border-bottom: 1px solid #cbd5e0;
		display: flex;
		justify-content: left;
		min-height: 35px;
		padding: 4px 12px;
		input[type='checkbox'] {
			display: none;
		}
		label {
			color: #4a5568;
			cursor: pointer;
			font-size: 18px;
			font-weight: 500;
			padding-left: 30px;
			position: relative;
			user-select: none;
			span {
				display: inline-block;
				margin-left: 8px;
				vertical-align: middle;
			}
			&::before {
				border: 2px solid #cbd5e0;
				content: '';
				height: 20px;
				left: 0;
				position: absolute;
				top: 0;
				transition: 0.3s ease;
				width: 20px;
			}
			&::after {
				background-color: #805ad5;
				content: '';
				display: none;
				height: 12px;
				left: 4px;
				position: absolute;
				top: 4px;
				width: 12px;
			}
		}
		input[type='checkbox']:checked + label::after {
			display: block;
		}
		input[type='checkbox']:checked + label::before {
			background-color: #805ad5;
			border-color: #805ad5;
		}

		.checkmark {
			color: #fff;
			display: none;
			font-size: 16px;
			left: 0;
			position: absolute;
			top: 50%;
			transform: translateX(-35%) translateY(-55%);
			z-index: 50;
			svg {
				height: 14px;
				width: 14px;
			}
			path {
				fill: #fff;
			}
		}

		input[type='checkbox']:checked + label .checkmark {
			display: inline-block;
		}
	}

	.date-picker-container {
		align-items: center;
		display: flex;
		position: relative;
	}
	.calendar-icon {
		color: #333;
		cursor: pointer;
		position: absolute;
		right: 10px;
		top: 12px;
	}

	.loader-section {
		align-items: center;
		background: #fff;
		display: flex;
		height: 70vh;
		justify-content: center;
		position: fixed;
		width: 625px;
		&__container {
			flex-wrap: wrap;
			text-align: center;
			width: 100%;
			&__title {
				color: #64738b;
				font-size: 25px;
				font-weight: 500;
			}
			&__message {
				color: #64738b;
				font-size: 20px;
				font-weight: 500;
				margin-bottom: 30px;
			}
		}
		svg {
			color: #e7951c;
			height: 83px;
			width: 83px;
		}
	}
}
::-webkit-scrollbar {
	width: 5px;
}

::-webkit-scrollbar-track {
	background: transparent;
}

::-webkit-scrollbar-thumb {
	background: #888;
	cursor: pointer;
}

::-webkit-scrollbar-thumb:hover {
	background: #555;
}
