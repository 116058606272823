// Variables
//
// @author Erik Galloway <egalloway@claruscare.com>
//
// Colors
// Options
// Alerts
// Box shadows
// Typography
// Animations
// Navbar
// Sidebar
// Inbox
// Buttons
// Panels
// Tooltips
// Tab bar

// Z-index list

// Colors
//
// The color scale below is from the Tailwind CSS color pallete.
// @see https://tailwindcss.com/docs/customizing-colors/#default-color-palette

$black: #000;
$white: #fff;

$gray-100: #f7fafc;
$gray-200: #edf2f7;
$gray-300: #e2e8f0;
$gray-400: #cbd5e0;
$gray-500: #a0aec0;
$gray-600: #718096;
$gray-700: #4a5568;
$gray-800: #2d3748;
$gray-900: #1a202c;

$red-100: #fff5f5;
$red-200: #fed7d7;
$red-300: #feb2b2;
$red-400: #fc8181;
$red-500: #f56565;
$red-600: #e53e3e;
$red-700: #c53030;
$red-800: #9b2c2c;
$red-900: #742a2a;

$orange-100: #fde6ce;
$orange-200: #fcd3a7;
$orange-300: #fabf7f;
$orange-400: #f9ad5d;
$orange-500: #f79a36;
$orange-600: #f5830a;
$orange-700: #c46908;
$orange-800: #985106;
$orange-900: #6c3a04;

$yellow-100: #fffff0;
$yellow-200: #fefcbf;
$yellow-300: #faf089;
$yellow-400: #f6e05e;
$yellow-500: #ecc94b;
$yellow-600: #d69e2e;
$yellow-700: #b7791f;
$yellow-800: #975a16;
$yellow-900: #744210;

$green-100: #f0fff4;
$green-200: #c6f6d5;
$green-300: #9ae6b4;
$green-400: #68d391;
$green-500: #48bb78;
$green-600: #38a169;
$green-700: #2f855a;
$green-800: #276749;
$green-900: #22543d;

$teal-100: #e6fffa;
$teal-200: #b2f5ea;
$teal-300: #81e6d9;
$teal-400: #4fd1c5;
$teal-500: #38b2ac;
$teal-600: #319795;
$teal-700: #2c7a7b;
$teal-800: #285e61;
$teal-900: #234e52;

$cyan-100: #b2ebf2;
$cyan-200: #80deea;
$cyan-300: #4dd0e1;
$cyan-400: #26c6da;
$cyan-500: #00bcd4;
$cyan-600: #00acc1;
$cyan-700: #0097a7;
$cyan-800: #00838f;
$cyan-900: #006064;

$blue-100: #ebf8ff;
$blue-200: #bee3f8;
$blue-300: #90cdf4;
$blue-400: #63b3ed;
$blue-500: #4299e1;
$blue-600: #3182ce;
$blue-700: #2b6cb0;
$blue-800: #2c5282;
$blue-900: #2a4365;

$indigo-100: #ebf4ff;
$indigo-200: #c3dafe;
$indigo-300: #a3bffa;
$indigo-400: #7f9cf5;
$indigo-500: #667eea;
$indigo-600: #5a67d8;
$indigo-700: #4c51bf;
$indigo-800: #434190;
$indigo-900: #3c366b;

$purple-100: #faf5ff;
$purple-200: #e9d8fd;
$purple-300: #d6bcfa;
$purple-400: #b794f4;
$purple-500: #9f7aea;
$purple-600: #805ad5;
$purple-700: #6b46c1;
$purple-800: #553c9a;
$purple-900: #44337a;

$violet-100: #efe6fd;
$violet-200: #d8bffb;
$violet-300: #c099f8;
$violet-400: #914df3;
$violet-500: #6200ee;
$violet-600: #5800d6;
$violet-700: #3b008f;
$violet-800: #2c006b;
$violet-900: #1d0047;

$pink-100: #fff5f7;
$pink-200: #fed7e2;
$pink-300: #fbb6ce;
$pink-400: #f687b3;
$pink-500: #ed64a6;
$pink-600: #d53f8c;
$pink-700: #b83280;
$pink-800: #97266d;
$pink-900: #702459;

$blue: $blue-500;
$indigo: $indigo-700;
$purple: $violet-500;
$pink: $pink-500;
$red: $red-500;
$orange: $orange-500;
$yellow: $yellow-500;
$green: $green-500;
$teal: $teal-500;
$cyan: $cyan-500;

$primary: $blue;
$secondary: $gray-500;
$success: $green;
$info: $blue-500;
$warning: $orange;
$danger: $red;
$light: $gray-100;
$dark: $gray-800;

$body-bg: $gray-100;

$provider-blue: $blue-200;
$provider-brown: $yellow-600;
$provider-gray: $gray-200;
$provider-green: $green-200;
$provider-orange: $orange-200;
$provider-pink: $pink-200;
$provider-purple: $purple-200;
$provider-red: $red-200;
$provider-teal: $teal-200;
$provider-turquoise: $cyan-200;
$provider-indigo: $indigo-200;
$provider-yellow: $yellow-200;

$theme-colors: (
	'material': $purple,
);

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-caret: true;
$enable-rounded: true;
$enable-shadows: false;
$enable-gradients: false;
$enable-transitions: true;
$enable-prefers-reduced-motion-media-query: true;
$enable-grid-classes: true;
$enable-pointer-cursor-for-buttons: true;
$enable-print-styles: true;
$enable-responsive-font-sizes: false;
$enable-validation-icons: true;
$enable-deprecation-messages: true;

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
	xs: 0,
	sm: 576px,
	md: 768px,
	lg: 992px,
	xl: 1200px,
);

// Alerts
$snackbar-bg: #323232;
$snackbar-border-radius: 2px;
$snackbar-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2),
	0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);

$snackbar-error-color: $red;
$snackbar-success-color: $green;
$snackbar-warning-color: $yellow;

// Box shadows
//
// Tailwind CSS default box shadows

$box-shadow-xs: 0 0 0 1px rgba(0, 0, 0, 0.05);
$box-shadow-sm: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
$box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
$box-shadow-md: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
	0 2px 4px -1px rgba(0, 0, 0, 0.06);
$box-shadow-lg: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
	0 4px 6px -2px rgba(0, 0, 0, 0.05);
$box-shadow-xl: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
	0 10px 10px -5px rgba(0, 0, 0, 0.04);
$box-shadow-2xl: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
$box-shadow-inner: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
$box-shadow-outline: 0 0 0 3px rgba(66, 153, 225, 0.5);

// Typography
//
// Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
	'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
	'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
$font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
	'Liberation Mono', 'Courier New', monospace;
$font-family-nunito: 'Nunito', $font-family-sans-serif;
$font-family-roboto: 'Roboto', $font-family-sans-serif;

$font-family-base: $font-family-sans-serif;

// Animations
//
// These are borrowed from Material Design CSS animation pattern

$animation-deceleration-timing: cubic-bezier(0, 0, 0.2, 1);
$animation-standard-timing: cubic-bezier(0.4, 0, 0.2, 1);
$animation-acceleration-timing: cubic-bezier(0.4, 0, 1, 1);
$animation-sharp-timing: cubic-bezier(0.4, 0, 0.6, 1);

// Navbar

$navbar-bg: $white;
$navbar-box-shadow: none;
$navbar-border: 1px solid $gray-400;
$navbar-height: 70px;

// Sidebar

$sidebar-width: 70px;
$sidebar-left: $sidebar-width;
$sidebar-background: $white;

// Inbox

$inbox-width-sm: 100%;
$inbox-width-md: 325px;
$inbox-width-lg: 350px;
$inbox-width-xl: 400px;

// Buttons
$btn-raised-box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
	0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
$btn-raised-active-box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
	0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
$btn-raised-disabled-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2),
	0 0 0 0 rgba(0, 0, 0, 0.14), 0 0 0 0 rgba(0, 0, 0, 0.12);
$btn-raised-hover-box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
	0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
$btn-raised-transition: box-shadow 280ms $animation-standard-timing;

// Panels

$panel-mask-background-color: rgba(0, 0, 0, 0.35);
$panel-mask-transition: all 500ms $animation-deceleration-timing;
$panel-container-width: 350px;
$panel-container-lg-width: 600px;
$panel-background-color: $white;
$panel-transition: transform 500ms $animation-deceleration-timing;
$panel-header-background: $gray-800;
$panel-header-border-color: $gray-400;
$panel-header-height: 3.25rem;
$panel-title-font-size: 1.25rem;

// Tooltips

$tooltip-background: rgba(0, 0, 0, 0.75);
$tooltip-border-radius: 0.25rem;
$tooltip-content: attr(data-tooltip);
$tooltip-font-size: 0.75rem;
$tooltip-padding-x: 4px;
$tooltip-padding-y: 8px;
$tooltip-min-width: 40px;
$tooltip-transition-duration: 150ms;
$tooltip-transition: opacity $tooltip-transition-duration
		$animation-deceleration-timing,
	transform $tooltip-transition-duration $animation-deceleration-timing;

// Tab bar
$tab-bar-primary: $purple;

$tab-bar-item-background-active: $tab-bar-primary;
$tab-bar-item-background-opacity-hover: 0.04;
$tab-bar-item-background-opacity-focus: 0.12;

$tab-bar-item-color-active: $tab-bar-primary;
$tab-bar-item-font-size: 0.875rem;
$tab-bar-item-font-weight: 500;
$tab-bar-item-height: 3rem;
$tab-bar-item-line-height: 2.25rem;
$tab-bar-item-padding-x: 1.5rem;
$tab-bar-item-padding-y: 0;
$tab-bar-item-text-tracking: 1.25;

$tab-bar-item-content-transition: color 150ms linear;
$tab-bar-item-content-transition-delay-active: 100ms;
$tab-bar-item-icon-text-spacer: 0.5rem;

$tab-bar-item-underline-transition: transform 250ms $animation-standard-timing;
$tab-bar-item-underline-border: 2px solid $tab-bar-primary;

// Z-index list

$zindex-dropdown: 1000;
$zindex-sticky: 1020;
$zindex-fixed: 1030;
$zindex-modal-backdrop: 1040;
$zindex-modal: 1050;
$zindex-popover: 1060;
$zindex-dialog-modal: $zindex-popover;
$zindex-progress-bar: $zindex-popover;
$zindex-tooltip: 1070;
$zindex-view-more: 1050;
$zindex-context-menu: $zindex-popover;
