// Layout helpers
//
// @author Erik Galloway <egalloway@claruscare.com>
//
// Container
// View
// View container
// Scroll lock

// App container
html,
body,
.app-container,
.app-layout {
	height: 100%;
	width: 100%;
}

// App view
.app-view {
	height: 100%;
	position: relative;
	width: 100%;
}

// View container
.app-view-container {
	height: 100%;
	margin-left: auto;
	margin-right: auto;
	min-height: 100%;
	padding-left: $sidebar-left;

	> .row {
		height: 100%;
	}
}

// Scroll lock

.scroll-lock {
	overflow-y: hidden;
}

// Single container class with breakpoint max-widths
.container,
  // 100% wide container at all breakpoints
  .container-fluid {
	@include make-container();
}
