// View more
//
// @author Alejandro Sanchez <asanchez@claruscare.com>
//
// container
// Header
// Body

// Container
.view-more {
	color: $gray-700;
	padding: 1rem;
	position: absolute;
	width: 18rem;
	z-index: $zindex-popover;
}

// Header
.view-more-header {
	background-color: $gray-300;
	border: 1px solid $gray-400;
	border-bottom: none;
	display: flex;
	padding: 1rem;
	z-index: $zindex-view-more;

	@include letter-spacing(1.5, 0.875rem);
	@include font-roboto($font-size: 1rem, $font-weight: 500);
}

// Body
.view-more-body {
	background-color: white;
	border: 1px solid $gray-400;
	color: $gray-700;
	padding: 0.5rem;
}
