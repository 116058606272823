// Forms - color picker
//
// @author Erik Galloway <egalloway@claruscare.com>
//
// Picker
// Option
// Radio

// Picker

.color-picker {
	display: flex;
	flex-flow: row wrap;
	justify-content: flex-start;
}

// Option

.color-picker-option {
	align-items: center;
	color: $gray-700;
	cursor: pointer;
	flex-direction: column;
	height: 3.5rem;
	justify-content: center;
	justify-content: center;
	margin: 0.25rem;
	min-width: 3.75rem;
	position: relative;
	text-align: center;

	@include letter-spacing(0.5, 0.75rem);
	@include font-roboto($font-size: 0.75rem, $font-weight: 500);
}

.color-picker-option-outter {
	border: 2px solid;
	border-radius: 0.25rem;
	box-shadow: $box-shadow-sm;
	display: flex;
	height: 1.75rem;
	justify-content: center;
	margin: 0 auto 0.25rem;
	padding: 2px;
	width: 1.75rem;

	.active > & {
		border: 2px solid;
	}

	.color-picker-option:not(.active) > & {
		border: 2px solid transparent !important;
	}
}

.color-picker-option-inner {
	border: 1px solid $white;
	border-radius: 0.25rem;
	box-shadow: $box-shadow-sm;
	display: block;
	height: 100%;
	opacity: 0.9;
	width: 100%;

	.active & {
		opacity: 1;
	}

	.color-picker-option:hover & {
		opacity: 0.75;
	}

	.color-picker-option:not(.active):hover & {
		opacity: 0.95;
	}
}

// Radio

.color-picker-input {
	box-sizing: border-box;
	clip: rect(0, 0, 0, 0);
	margin: 0;
	padding: 0;
	pointer-events: none;
	position: absolute;
}
