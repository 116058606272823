// Sidebar
//
// @author Erik Galloway <egalloway@claruscare.com>
//
// Sidebar
// Brand
// Items
// Link

// Sidebar

.sidebar {
	background-color: $sidebar-background;
	border-right: 1px solid $gray-400;
	bottom: 0;
	display: flex;
	flex-direction: column;
	font-family: $font-family-nunito;
	position: absolute;
	top: 0;
	width: $sidebar-left;
}

// Brand

.sidebar-brand {
	align-items: center;
	border-bottom: 1px solid $gray-400;
	color: $white;
	display: flex;
	flex-flow: row nowrap;
	font-family: $font-family-nunito;
	font-size: 1.25rem;
	font-weight: 500;
	height: 70px;
	justify-content: center;
	line-height: $navbar-height;
	margin-bottom: 0.5rem;
	text-align: center;
	transition: all 0.4s $animation-standard-timing;
	width: 100%;
	z-index: 10;

	@include hover-focus {
		background: $white;
		color: $gray-800;
		outline: 0;
		text-decoration: none;
	}
}

.sidebar-brand > img {
	height: auto;
	width: 3.125rem;
}

// Items

.sidebar-items {
	align-items: center;
	display: flex;
	flex-direction: column;
	height: calc(100vh - 70px);
	justify-content: space-between;
	justify-self: center;
	margin: auto 0;
	max-height: 400px;
	padding: 0;
	padding-top: 0.5rem;

	@media (max-height: 325px) {
		overflow-x: hidden;
		overflow-y: scroll;
	}
}

.app-version {
	align-items: center;
	bottom: 0;
	display: flex;
	flex-direction: column;
	font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
		'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
		'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
	font-size: 15px;
	margin-bottom: 2px;
	position: absolute;
	text-align: center;

	@media (max-height: 325px) {
		overflow-x: hidden;
		overflow-y: scroll;
	}
}

// Link

.sidebar-link {
	align-items: center;
	border-radius: 50%;
	color: $gray-900;
	display: flex;
	flex-direction: column;
	font-size: 26px;
	height: 50px;
	justify-content: center;
	padding: 0.75rem;
	position: relative;
	transition: all 0.4s;
	width: 50px;

	@include hover-focus {
		outline: 0;
		text-decoration: none;
	}

	svg {
		display: inline-block;
		fill: currentColor;
		font-size: 26px;
		height: 26px;
		width: 26px !important;
	}

	&:focus {
		background: rgba($purple, 0.12);
	}

	&:hover {
		background: rgba($purple, 0.08);
		color: $gray-800;
	}

	&.router-link-active {
		background: rgba($purple, 0.1);

		&:hover {
			background: rgba($purple, 0.14);
		}

		&:focus {
			background: rgba($purple, 0.12);
		}
	}

	&.router-link-active,
	&.router-link-active > svg {
		color: $purple;
	}
}
