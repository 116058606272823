// Avatar
//
// @author Alejandro Sanchez <asanchez@claruscare.com>
// @author Erik Galloway <egalloway@claruscare.com>
//
// Avatar

// Avatar
.avatar {
	align-items: center;
	border: 1px solid;
	border-radius: 50%;
	display: flex;
	font-size: 1.25rem;
	font-weight: 800;
	justify-content: center;
	margin-right: 1.5rem;
	min-width: 2.8125rem;
	padding: 0.5rem 0.65rem;
	text-align: center;

	@include font-roboto();
}

.avatar-count {
	right: -5px;
	top: -10px;
}
