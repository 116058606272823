// Tables
//
// @author Alejandro Sanchez <asanchez@claruscare.com>
//
// Table
// Table responsive

// Table
.table {
	border: 0;
	border-collapse: collapse;
	color: $gray-900;
	margin-bottom: 1rem;
	min-width: 100%;
	table-layout: fixed;
	white-space: nowrap;

	caption {
		padding: 1rem;

		@include font-roboto($font-size: 0.875rem, $font-weight: 400);
	}
}

// Table responsive
.table-responsive {
	background-color: $white;
	border: 1px solid rgba(0, 0, 0, 0.12);
	border-radius: 0.25rem;
	box-sizing: border-box;
	display: inline-flex;
	flex-direction: column;
	margin-bottom: 1rem;
	-webkit-overflow-scrolling: touch;
	overflow-x: auto;
	width: 100%;

	> .table {
		margin-bottom: 0;
	}
}

.table th,
.table td {
	border-bottom: 1px solid $gray-400;
	padding: 1rem;
	text-align: left;
}

.table th {
	box-sizing: border-box;
	letter-spacing: 0.0071428571em;
	line-height: 1.375rem;
	overflow: hidden;
	text-align: left;
	text-overflow: ellipsis;

	@include font-roboto($font-size: 0.875rem, $font-weight: 500);
}

.table tbody {
	letter-spacing: 0.0178571429em;
	line-height: 1.25rem;
	text-decoration: inherit;
	text-transform: inherit;

	@include font-roboto($font-size: 0.875rem, $font-weight: 400);

	> tr:not(:last-child) {
		border-bottom: 1px solid rgba(0, 0, 0, 0.12);
	}

	td {
		box-sizing: border-box;
		letter-spacing: 0.0178571429em;
		line-height: 1.25rem;
		overflow: hidden;
		text-decoration: inherit;
		text-overflow: ellipsis;
		text-transform: inherit;

		@include font-roboto($font-size: 0.875rem, $font-weight: 400);
	}

	td.overflow-visible {
		overflow: visible;
	}
}

// Condensed

.table-condensed {
	thead th,
	tbody td {
		padding: 0.6rem;
	}
}
