// Navbar
//
// @author Erik Galloway <egalloway@claruscare.com>
//
// Navbar
// Toggler
// Logo
// Navbar group
// Search clear button
// Padding utility
// Calendar title
// Notifications dropdown

// Navbar
.navbar {
	background-color: $navbar-bg;
	border-bottom: $navbar-border;
	box-shadow: $navbar-box-shadow;
	display: flex;
	flex-flow: row nowrap;
	height: $navbar-height;
	left: $sidebar-width;
	padding: 0 1.5rem;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 1001;

	@include media-breakpoint-down(sm) {
		background: $white;
		border-bottom: 1px solid $gray-400;
		padding: 0 1rem;
	}

	.has-inbox:not(.has-narrow-inbox) & {
		@include media-breakpoint-only(md) {
			left: $sidebar-width + $inbox-width-md;
		}

		@include media-breakpoint-only(lg) {
			left: $sidebar-width + $inbox-width-lg;
		}

		@include media-breakpoint-only(xl) {
			left: $sidebar-width + $inbox-width-xl;
		}
	}

	@include media-breakpoint-up(md) {
		.has-inbox.has-narrow-inbox & {
			left: $sidebar-width + 250px;
		}
	}
}

// Toggler
.navbar-toggler {
	border: 0;
	color: rgba(0, 0, 0, 0.5);
	margin-left: 1rem;
}

// Logo
.navbar-brand > .logo {
	height: 30px;
	width: 30px;
}

// Navbar group
.navbar-group {
	display: flex;
	flex-flow: row nowrap;
	height: $navbar-height;
	list-style-type: none;
	margin: 0;
	padding: 0;
	position: relative;
	width: 100%;
	z-index: 1;
}

.navbar-group .nav-item {
	align-items: center;
	display: flex;
	justify-content: center;
	margin-left: 0.25rem;
	max-height: $navbar-height;
	min-width: 3rem;
}

.navbar-group .nav-link {
	align-items: center;
	border: 1px solid transparent;
	border-radius: 50%;
	color: $gray-700;
	display: flex;
	justify-content: center;
	padding: 0.7rem;
	position: relative;
	text-align: center;
	transition: 200ms $animation-standard-timing;

	@include hover-focus {
		text-decoration: none;
	}

	.new-notifications {
		color: color('red');
		font-size: 0.75rem;
		position: absolute;
		right: 0.5rem;
		top: 0.125rem;
	}
}

// User account dropdown
.nav-user {
	margin-left: 0.5rem;
	position: relative;

	> .nav-link {
		border-radius: 0;
		color: $gray-700;
		min-height: $navbar-height;
		position: relative;
		text-align: left;

		@include font-roboto();
		@include caret();

		@include media-breakpoint-down(md) {
			padding: 1.0625rem 0.3125rem 1.0625rem 3.5625rem;
		}

		@include hover-focus {
			outline: 0;
		}
	}

	.user-avatar {
		height: 2rem;
		margin-right: 0.5rem;
		width: 2rem;
	}

	.user-avatar-img {
		border-radius: 50%;
		height: 2rem;
		width: 2rem;
	}

	.account-user {
		display: block;
		font-size: 0.9375rem;
	}

	@include media-breakpoint-down(md) {
		.account-user {
			display: none;
		}
	}
}

// Search clear button

.search-clear-btn {
	color: $gray-700;
	cursor: pointer;
	display: flex;
	margin: 0.9rem;
	position: absolute;
	right: 0;
}

// Padding utility

.padding-navbar {
	padding-top: $navbar-height;
}

// Calendar title
.nav-calendar-title {
	color: $gray-700;
	display: none;

	@include font-roboto($font-size: 1.1rem, $font-weight: 400);
	@include letter-spacing(0.35, 1.1rem);

	@include media-breakpoint-up(md) {
		display: flex;
	}
}

// Notifications dropdown
.nav-notifications {
	align-items: center;
	display: flex;
	justify-content: flex-end;
	max-height: $navbar-height;
	min-width: 5rem;
	position: relative;

	@include media-breakpoint-up(lg) {
		min-width: 12rem;
	}
}
