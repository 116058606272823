// Panel component
//
// @author Erik Galloway <egalloway@claruscare.com>
//
// Mask
// Transition
// Panel
// Header
// Title
// Body
// Form
// Illustrations

// Mask

.panel-mask {
	background-color: $panel-mask-background-color;
	bottom: 0;
	display: flex;
	left: 0;
	opacity: 1;
	position: fixed;
	right: 0;
	top: 0;
	z-index: $zindex-modal-backdrop;
}

// Transition

.panel-transition-enter-active {
	transition: $panel-mask-transition;
}

.panel-transition-leave-active {
	transition: $panel-mask-transition;
}

.panel-transition-enter,
.panel-transition-leave-to {
	background-color: rgba(0, 0, 0, 0);
}

// Container

.panel-container {
	position: relative;
	transition: width 1s $animation-deceleration-timing;

	.panel-mask:not(.panel-bottom) & {
		height: 100%;
		margin-left: auto;
		width: $panel-container-width;

		&.panel-large {
			width: 100%;

			@include media-breakpoint-up(sm) {
				width: $panel-container-lg-width;
			}
		}
	}
}

// Panel

.panel {
	background-color: $panel-background-color;
	display: flex;
	flex-flow: column nowrap;
	height: 100%;
	transition: $panel-transition;
	width: 100%;

	.panel-transition-enter.panel-bottom &,
	.panel-transition-leave-to.panel-bottom & {
		transform: translateY(100%);
	}

	.panel-transition-enter:not(.panel-bottom) &,
	.panel-transition-leave-to:not(.panel-bottom) & {
		transform: translateX(100%);
	}
}

// Header

.panel-header {
	align-items: center;
	background-color: $panel-header-background;
	border-bottom: 1px solid $panel-header-border-color;
	color: $white;
	display: flex;
	flex-shrink: 0;
	justify-content: center;
	min-height: $navbar-height;
	padding-left: $panel-header-height + 0.5rem;
	padding-right: 0.5rem;
	position: relative;
	width: 100%;

	.panel-bottom & {
		padding-left: 0.5rem;
		padding-right: $panel-header-height + 0.5rem;
	}
}

// Title

.panel-title {
	align-items: center;
	display: flex;
	height: 100%;
	margin: 0 24px 0 auto;

	.panel-bottom & {
		margin: 0 -3.25rem 0 0;
	}

	@include text-truncate();
	@include browser-font-smooth();
	@include font-roboto(500, $panel-title-font-size);
	@include letter-spacing(0.25, $panel-title-font-size);
}

// Body

.panel-body {
	display: flex;
	flex-flow: column nowrap;
	height: 100%;
	overflow-y: auto;
	padding: 1rem 1rem 4.5rem;
}

.panel-body-header {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

// Form

.panel-form {
	display: flex;
	flex-flow: column nowrap;
	width: 100%;
}

// Illustrations

.panel-illustration {
	height: auto;
	margin: 1rem auto 2rem;
	max-height: 30%;
	max-width: 40%;

	@include media-breakpoint-up(sm) {
		max-width: 50%;
	}

	.panel-bottom & {
		margin: 0;
		max-height: 60%;
		max-width: 45%;
	}
}

.panel-description {
	line-height: 1.75;
	margin: 1.5rem 0;
	padding: 1rem;

	@include letter-spacing(0.75, 1.25rem);
	@include font-roboto($font-size: 1.25rem, $font-weight: 400);
}
