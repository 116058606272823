// Calendar
//
// @author Alejandro Sanchez <asanchez@claruscare.com>
//
// View
// Container
// Header
// Provider colors
// Provider group event coverage table

// View
.calendar {
	display: flex;
	flex-direction: column;
	margin-top: $navbar-height;
	overflow-x: hidden;
	overflow-y: scroll;
	padding: 0 1.5rem;
	width: 100%;
}

// Header
.calendar-header {
	align-items: center;
	background: $white;
	border: 1px solid $gray-200;
	border-radius: 0.25rem;
	box-shadow: $box-shadow-md;
	color: $gray-700;
	display: flex;
	height: 3.5rem;
	justify-content: space-between;
	padding: 0.75rem 1rem;
	text-transform: uppercase;
}

.calendar-header-center {
	align-items: center;
	display: flex;
	flex-flow: row nowrap;
	font-size: 1rem;
	height: 100%;

	@include media-breakpoint-up(lg) {
		margin-left: auto;
		margin-right: auto;
		padding-left: 100px;
	}
}

.calendar-title-sm {
	margin: 0 auto;
	overflow: hidden;
	padding: 0 0.25rem;
	text-overflow: ellipsis;
	white-space: nowrap;

	@include letter-spacing(1.25, 1rem);
	@include font-roboto($font-size: 0.875rem, $font-weight: 500);

	@include media-breakpoint-up(sm) {
		display: none;
	}
}

.calendar-header-title {
	align-items: center;
	display: inline-flex;
	height: 100%;
	justify-content: center;
	min-width: 135px;

	@include letter-spacing(1.25, 1rem);
	@include font-roboto($font-size: 0.875rem, $font-weight: 500);
}

.calendar-header-btn {
	align-items: center;
	border-radius: 50%;
	color: $purple;
	display: flex;
	height: 1.5rem;
	justify-content: center;
	padding: 0.25rem 0;
	width: 1.5rem;

	&.previous {
		margin-right: 0.5rem;
	}

	&.next {
		margin-left: 0.5rem;
	}

	&:focus {
		outline: none;
	}

	&:hover {
		background: rgba($purple, 0.04);
	}

	> svg {
		font-size: 1rem;
	}
}

.calendar-header-views {
	display: none;

	@include media-breakpoint-up(md) {
		display: flex;
		flex-flow: row nowrap;
		height: 100%;
		justify-content: space-between;
		width: 140px;
	}
}

.calendar-header-view-btn {
	border-radius: 50%;
	color: $gray-800;
	height: 1.875rem;
	width: 1.875rem;

	&.active {
		background: rgba($purple, 0.12);
		color: $purple;
	}

	&:focus {
		background: rgba($purple, 0.04);
		outline: none;
	}

	&:hover {
		background: rgba($purple, 0.08);
	}

	> svg {
		font-size: 1rem;
	}
}

.calendar-overview {
	display: flex;
	flex-direction: column;
	height: 100%;
	overflow-x: scroll;
	width: 100%;
}

.calendar-overview::-webkit-scrollbar {
	visibility: visible;
}

.calendar-overview::-webkit-scrollbar-thumb {
	background-color: rgb(0, 0, 0, 0.5);
	border: 2px solid $white;
	border-radius: 8px;
}

.calendar-row-header {
	align-items: center;
	display: flex;
	flex-flow: row nowrap;
	min-height: 56px;
	width: 100%;
}

.calendar-header-cell {
	align-items: center;
	border: 1px solid $gray-400;
	border-left: none;
	color: $gray-700;
	display: flex;
	height: 100%;
	justify-content: center;
	min-height: 56px;
	min-width: 200px;
	padding: 1rem;
	position: relative;
	text-align: left;
	text-transform: uppercase;
	width: 25%;

	@include letter-spacing(1.25, 1rem);
	@include font-nunito($font-size: 0.95rem, $font-weight: 700);

	&:first-child {
		border-left: 1px solid $gray-400;
	}

	&.first {
		padding-left: 2rem;

		.calendar-action {
			left: 0;
			margin: 0;
			position: absolute;
		}
	}

	&.last {
		padding-right: 2rem;

		.calendar-action {
			margin: 0;
			position: absolute;
			right: 0;
		}
	}
}

.calendar-row {
	align-items: center;
	display: flex;
	flex-flow: row nowrap;
	min-height: 14rem;
	width: 100%;
}

.calendar-cell-title {
	align-items: center;
	background: $gray-200;
	border: 1px solid $gray-400;
	border-top: none;
	color: $gray-700;
	display: flex;
	height: 14rem;
	justify-content: center;
	min-width: 200px;
	padding: 1rem;
	text-transform: uppercase;
	white-space: normal;
	width: 25%;

	@include letter-spacing(1.25, 1rem);
	@include font-nunito($font-size: 0.95rem, $font-weight: 700);

	> a {
		text-align: center;
	}
}

.calendar-cell {
	background: $white;
	border: 1px solid $gray-400;
	border-left: none;
	border-top: none;
	display: flex;
	flex-direction: column;
	height: 14rem;
	min-width: 200px;
	overflow-y: auto;
	padding: 0.75rem;
	width: 25%;

	&:first-child {
		border-left: 1px solid $gray-400;
	}
}

.calendar-cell-content {
	display: flex;
	flex-direction: column;

	@include letter-spacing(0.1, 0.875rem);
	@include font-roboto($font-size: 0.875rem, $font-weight: 400);
}

.calendar-cell-content-timestamp {
	color: $gray-600;
	font-weight: 500;
}

.calendar-cell-content-provider {
	color: $gray-800;
	font-size: 0.9rem;
	font-weight: 500;
	margin-bottom: 0.25rem;
	padding: 0.1rem 0.25rem;
	width: 100%;

	@include letter-spacing(0.1, 0.9rem);
}

.calendar-actions {
	align-items: center;
	display: flex;
	flex-flow: row nowrap;
	flex-shrink: 0;
	justify-content: space-between;
	margin: 0.5rem 0;
	padding: 0.5rem 0;
	width: 100%;
}

.calendar-action {
	align-items: center;
	color: $purple;
	display: inline-flex;
	height: 100%;
	justify-content: center;
	margin-left: 0.25rem;
	min-height: 2rem;
	text-transform: uppercase;
	transition: all 150ms $animation-standard-timing;
	white-space: nowrap;
	width: 2rem;

	@include letter-spacing(1.5, 0.875rem);

	&.small {
		padding: 0;
	}

	&:focus {
		background: rgba($purple, 0.08);
		outline: 0;
	}

	&:hover {
		background: rgba($purple, 0.04);
		color: $gray-800;
	}
}

.calendar-action-icon {
	align-items: center;
	display: inline-flex;
	justify-content: center;

	&.small {
		margin: 0;
		padding: 0;
	}
}

.calendar-print-btn,
.calendar-gap-btn {
	display: none;

	@include media-breakpoint-up(sm) {
		display: inline-flex;
	}
}

// Provider colors

.provider-bg-blue {
	background: $provider-blue;
	border: 1px solid $blue-400;
	color: $blue-900;
}

.provider-bg-brown {
	background: $provider-brown;
	border: 1px solid $yellow-800;
	color: #3b2108;
}

.provider-bg-gray {
	background: $provider-gray;
	border: 1px solid $gray-400;
	color: $gray-900;
}

.provider-bg-green {
	background: $provider-green;
	border: 1px solid $green-400;
	color: $green-900;
}

.provider-bg-orange {
	background: $provider-orange;
	border: 1px solid $orange-400;
	color: $orange-900;
}

.provider-bg-indigo {
	background: $provider-indigo;
	border: 1px solid $indigo-400;
	color: $indigo-900;
}

.provider-bg-purple {
	background: $provider-purple;
	border: 1px solid $purple-400;
	color: $purple-900;
}

.provider-bg-pink {
	background: $provider-pink;
	border: 1px solid $pink-400;
	color: $pink-900;
}

.provider-bg-red {
	background: $provider-red;
	border: 1px solid $red-400;
	color: $red-900;
}

.provider-bg-teal {
	background: $provider-teal;
	border: 1px solid $teal-400;
	color: $teal-900;
}

.provider-bg-turquoise {
	background: $provider-turquoise;
	border: 1px solid $cyan-400;
	color: $cyan-900;
}

.provider-bg-indigo {
	background: $provider-indigo;
	border: 1px solid $indigo-400;
	color: $indigo-900;
}

.provider-bg-yellow {
	background: $provider-yellow;
	border: 1px solid $yellow-400;
	color: $yellow-900;
}

.calendar-print {
	height: 100%;
}

.calendar-print-header {
	display: none;
}

.fc-daygrid-day {
	background: $white;
}

.with-calendar-gaps .has-calendar-gap {
	&.fc-daygrid-day,
	&.fc-daygrid-today {
		background-color: $red-100 !important;
	}

	.fc-daygrid-day-number {
		background-color: $red-500;
		color: $gray-200;
		font-weight: 600;
	}
}

@media print {
	.app-view {
		margin-top: 0;
	}

	.app-view-container {
		padding: 0;
	}

	.navbar,
	.sidebar,
	.inbox,
	#calendar-topbar,
	.calendar-header {
		display: none !important;
	}

	.calendar-print-header {
		border-bottom: 1px solid $gray-900;
		display: flex;
		justify-content: space-between;

		@include font-roboto($font-size: 1.25rem, $font-weight: 500);
	}

	.calendar-print {
		page-break-before: avoid;
		zoom: 60%;
	}

	@page {
		size: landscape;
	}

	.event-secondary-provider {
		color: $gray-900 !important;
	}
}
.fc :not(.fc-daygrid-event-harness-abs) .fc-daygrid-event {
	border-color: transparent !important;
}

.fc-h-event {
	background-color: transparent !important;

	.fc-event-main {
		border-color: $gray-800;
		color: $gray-900 !important;
	}
}

.fc-daygrid-event {
	align-items: flex-start !important;
	border-color: transparent !important;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;

	@include font-roboto($font-size: 0.75rem);

	.fc-event-time {
		font-size: 0.75rem;
		font-weight: 500;
		margin-right: 0 !important;
		white-space: normal;

		@include letter-spacing(1, 0.75rem);
	}
}

.fc-timegrid-event .fc-event-time {
	white-space: normal !important;
}

.fc-list-event .fc-list-event-title,
.fc-list-event .fc-list-event-time,
.fc-list-day-text,
.fc-list-day-side-text {
	@include font-roboto($font-size: 1rem);
}

.fc-list-event .fc-event-time {
	font-size: 0.875rem;
	font-weight: 500;
	margin-bottom: 0.25rem;

	@include letter-spacing(1.25, 0.875rem);
}

.fc-list-day-text,
.fc-list-day-side-text,
.fc-list-event .fc-list-event-time {
	font-weight: 500;

	@include letter-spacing(1.25, 1rem);
}

.fc-event-title-wrapper {
	width: 100%;
}

.fc-event-content-wrapper {
	display: flex;
	width: 100%;
}

.fc-custom-dot {
	border-radius: 50%;
	display: inline-block;
	height: 0.625rem;
	margin-right: 0.25rem;
	margin-right: 0.25rem;
	margin-top: 0.3125rem;
	width: 0.625rem;
}

.fc-custom-title,
.fc-event-subtitle {
	align-items: center;
	border-radius: 0.25rem;
	display: flex;
	padding: 0.15rem 0.1rem 0.15rem 0.25rem;
	pointer-events: none;
	white-space: normal;
	width: 100%;

	@include letter-spacing(0.05, 0.875rem);
	@include font-roboto($font-size: 0.875rem, $font-weight: 400);
}

.with-secondary .fc-custom-title {
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}

.with-secondary .fc-event-subtitle {
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}

.fc-linked-partner-calendar .fc-event-subtitle {
	align-items: center;
	border-top: 0;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
	display: flex;
}

.with-linked-partner-calendar {
	border-bottom: 0;
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}

.fc-list-event-dot {
	display: none !important;
}

.fc-list-event-graphic {
	display: none;
}

.fc .fc-daygrid-day-number {
	font-size: 0.75rem;
	font-weight: 500;
	padding: 2px 4px !important;
}

.fc-event-time {
	align-items: center;
	display: flex;
	justify-content: space-between;
	width: 100%;
}

.calendar-create-icon {
	align-items: center;
	display: flex;
	justify-content: center;

	@include media-breakpoint-up(sm) {
		display: none;
	}
}

.calendar-create-button {
	display: none;
	margin-right: 0.5rem;
	white-space: nowrap;

	@include media-breakpoint-up(sm) {
		display: inline-flex;
	}
}

// Provider group event coverage table

.group-coverage-row-provider-name {
	max-width: 250px;
	white-space: normal;
}

.group-coverage-table-provider-select {
	min-width: 150px;
}
