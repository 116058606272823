// Loader
//
// @author Alejandro Sanchez <asanchez@claruscare.com>
//
// Container
// Loader background
// Loader bar

// Container
.loader > div {
	height: 0.2rem;
	opacity: 1;
	position: fixed;
	z-index: $zindex-progress-bar;
}

// Loader background
.loader-bg {
	width: 100%;
}

// Loader bar
.loader-bar {
	background-color: $orange;
	transition: width 100ms;
}
